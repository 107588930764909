import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Autocomplete, Stack, useMediaQuery, Checkbox, FormControlLabel } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { selectLocationNotesConf, selectNotificationMethodConf } from 'redux/configurationSlice';
import { selectMeasurePoints } from 'redux/locationsSlice';
import { AlertSnackbar } from 'components';
import API from 'api';
import { enGB, hr } from "date-fns/locale"

export default function NewNoteDialog(props) {
    const { open, handleDialogClose, noteType, notificationTypes, noteTitle, noteText, groupLevel, locationId, lockLocation } = props;
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const user = useSelector(userSelector);
    const locations = useSelector(selectMeasurePoints);
    const noteOptions = useSelector(selectLocationNotesConf);
    const notificationOptions = useSelector(selectNotificationMethodConf);

    const [date, setDate] = useState(new Date());
    const [selectedNoteType, setSelectedNoteType] = useState(noteType ? noteOptions.find(opt => opt.value === noteType) : null);
    const [selectedNotificationTypes, setSelectedNotificationTypes] = useState(notificationTypes ? notificationTypes.map(el => notificationOptions.find(opt => opt.value === el)) : []);
    const [selectedTitle, setSelectedTitle] = useState(noteTitle ? noteTitle : '');
    const [selectedText, setSelectedText] = useState(noteText ? noteText : '');
    const [selectedGroupLevel, setSelectedGroupLevel] = useState(groupLevel ? groupLevel : false)
    const [selectedLocation, setSelectedLocations] = useState(locationId ? locations.find(loc => loc._id === locationId) : null);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const changeDate = (value) => {
        setDate(value);
    };

    const handleChange = (event) => {
        if(event.target.name === "title") setSelectedTitle(event.target.value)
        else setSelectedText(event.target.value);
    };

    const postNote = () => {
        const noteObject = {
            user: user.email,
            locationId: selectedLocation._id,
            timestamp: date,
            notificationMethod: selectedNotificationTypes.map(el => el.key),
            type: selectedNoteType.key,
            groupLevel: selectedGroupLevel,
            ...(selectedGroupLevel) && { locationGroupId: selectedLocation.locationGroupId },
            title: selectedTitle,
            content: selectedText
        }
        return API.notes.postNote(user.token, noteObject).catch((error) => {
            console.error("An error has occured: ", error);
            setAlert({ open: true, message: error.data, severity: "error" });
        })
    };

    const resetData = () => {
        setDate(new Date())
        setSelectedNoteType(noteType ? noteOptions.find(opt => opt.value === noteType) : null)
        setSelectedNotificationTypes(notificationTypes ? notificationTypes.map(el => notificationOptions.find(opt => opt.value === el)) : [])
        setSelectedTitle(noteTitle ? noteTitle : '')
        setSelectedText(noteText ? noteText : '')
        setSelectedGroupLevel(groupLevel ? groupLevel : false)
        setSelectedLocations(locationId ? locations.find(loc => loc._id === locationId) : null)
        handleDialogClose();
    };

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message} />
        <Dialog fullScreen={smallScreen} open={open} onClose={resetData}>
            <DialogTitle><FormattedMessage id="NOTES" /></DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={2} sx={{ pt: 1 }}>
                    <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                        <DateTimePicker
                            label={intl.formatMessage({ id: "NOTE.NOTE_DATE" })}
                            value={date}
                            onChange={changeDate}
                            renderInput={(params) => <TextField {...params} size='small' fullWidth />}
                        />
                    </LocalizationProvider>
                    <Autocomplete
                        size='small'
                        freeSolo
                        defaultValue={undefined}
                        disabled={lockLocation}
                        options={locations}
                        getOptionLabel={(location) => location.name || ""}
                        value={selectedLocation}
                        onChange={(e, location) => setSelectedLocations(location)}
                        renderInput={(params) => (
                            <TextField {...params} key={locations.map(location => location._id)} label={<FormattedMessage id="ALL_LOCATIONS" />} />
                        )}
                        fullWidth
                    />
                    <Autocomplete
                        multiple
                        size="small"
                        limitTags={3}
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        disableCloseOnSelect
                        filterSelectedOptions
                        onChange={(event, newValue) => {
                            setSelectedNotificationTypes(newValue);
                        }}
                        id="multiple-limit-tags"
                        defaultValue={undefined}
                        value={selectedNotificationTypes}
                        options={notificationOptions}
                        getOptionLabel={(option) => intl.formatMessage({ id: 'NOTE.NOTIFICATION_OPTION.' + option.value }) || ""}
                        renderInput={(params) => (
                            <TextField {...params} label={<FormattedMessage id="NOTE.NOTIFICATION_TYPE" />} />
                        )}
                        fullWidth
                    />
                    <Autocomplete
                        size='small'
                        freeSolo
                        defaultValue={undefined}
                        value={selectedNoteType}
                        onChange={(event, value) => {
                            setSelectedNoteType(value)
                        }}
                        options={noteOptions}
                        getOptionLabel={(option) => intl.formatMessage({ id: 'NOTE.NOTE_OPTION.' + option.value }) || ""}
                        renderInput={(params) => <TextField {...params} label={<FormattedMessage id="NOTE.NOTE_TYPE" />} />}
                    />
                    <TextField
                        autoFocus
                        size="small"
                        variant="outlined"
                        name="title"
                        label={<FormattedMessage id="NOTE.NOTE_TITLE" />}
                        value={selectedTitle}
                        onChange={handleChange}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        size="small"
                        variant="outlined"
                        multiline
                        rows="5"
                        name="notes"
                        label={<FormattedMessage id="NOTE.NOTE_TEXT" />}
                        value={selectedText}
                        onChange={handleChange}
                        fullWidth
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={selectedGroupLevel}
                            onChange={() => setSelectedGroupLevel(!selectedGroupLevel)} />}
                        label={<FormattedMessage id="NOTE.NOTIFY_GROUP" />}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button disabled={!(selectedNotificationTypes.length && selectedLocation && selectedNoteType && selectedText && selectedTitle)} onClick={() => { postNote(); resetData() }}><FormattedMessage id="ADD" /></Button>
            </DialogActions>
        </Dialog>
    </>
}
