import { palette } from './chartsCollorPalette';

const defaultOptions  = {
    colors: palette,
    pointRadius: 0,
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        intersect: false,
        mode: 'nearest',
        axis: 'x'
    },
    plugins: {
        legend: {
            display: true,
            labels: {
                usePointStyle: true,
                font: {
                    family: 'Roboto Condensed'
                }
            }
        },
        tooltip: {
            usePointStyle: true,
            position: 'nearest',
            callbacks: {
                labelPointStyle: (context) => { return { pointStyle: 'circle' } }
            }
        }
    },
    scales: {
        y: {
            grid: {
                display: false,
            },
            ticks: {
                font: {
                    family: 'Roboto Condensed'
                }
            }
        },
        x: {
            grid: {
                display: false,
            },
            ticks: {
                font: {
                    family: 'Roboto Condensed'
                }
            }
        }
    }
}

export default defaultOptions;