import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { AboutView } from 'components';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';



function AboutPage(props) {
    const { publicView } = props;
    return <EmptyState component={<AboutView publicView={publicView} />} />;
}

export default function AboutPageComponent(props) {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<AboutPage />} />;
    else return <PublicLayout page={<AboutPage publicView />} />;
};
