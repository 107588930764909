import { useCallback, useState, useRef } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { selectPoIConf } from "redux/configurationSlice";
import { userSelector } from "redux/userSlice";
import { PictureUploadButton, MapComponent, MapElementNew, AlertSnackbar } from "components";
import { Vector } from 'ol/source';
import { Dialog, DialogTitle, DialogContent, TextField, Autocomplete, Avatar, Stack, Button, useMediaQuery, Divider, DialogActions, Grid } from "@mui/material";
import { PushPin, Villa, HomeWork, AddHomeWork } from "@mui/icons-material";
import API from 'api';


export default function NewPoIDialog(props) {
    const { open, onClose, location, pinnedPosition } = props;
    const { token } = useSelector(userSelector);
    const intl = useIntl();
    const fullScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const poiTypes = useSelector(selectPoIConf);
    const [selectedType, setSelectedType] = useState(0);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [position, setPosition] = useState(pinnedPosition);
    const [alert, setAlert] = useState({ open: false });
    const markerSource = useRef(new Vector());

    const onAlertClose = () => setAlert({ ...alert, open: false });
    const onSubmit = useCallback(() => {
        const newPoi = {
            type: selectedType,
            name: name,
            description: {
                [intl.locale]: description
            },
            latitude: position[1],
            longitude: position[0],
            ...(!!location.measurePoints && { group: true })
        };

        API.poi.createPointOfInterest(token, location._id, newPoi, image).then((items) => {
            setName("");
            setSelectedType(0);
            setDescription("");
            onClose();
        }).catch((error) => {
            setAlert({ open: true, message: error.data, severity: "error" });
            console.error("Get Location Points of Interest has failed, reason: ", error);
        });
    }, [name, description, selectedType, position, location, token, image, intl, onClose]);

    const renderPinIcon = useCallback(() => {
        switch (selectedType) {
            case 1:
                return <Villa />;
            case 2:
                return <HomeWork />;
            case 3:
                return <AddHomeWork />;
            case 0:
            default:
                return <PushPin />;
        }

    }, [selectedType]);

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message} />
        <Dialog
            open={open}
            fullScreen={fullScreen}
            fullWidth maxWidth='sm'
            aria-labelledby="new-poi-form"
            scroll="paper"
        >
            <DialogTitle>
                <FormattedMessage id="ADD_POI" /> ({location.name})
            </DialogTitle>
            
            <Divider variant="middle" sx={{ mx: 3 }} />

            <DialogContent sx={fullScreen ? null : { px: 8 }}>
                <Grid container direction={fullScreen ? "column-reverse" : "row"} spacing={fullScreen ? 0 : 1}>
                    <Grid item xl={6} lg={6} md={6}>
                        <Stack direction="row" spacing={1}>
                            <Avatar sx={{ bgcolor: "primary.main", mt: 2, mb: 2 }}>{renderPinIcon()}</Avatar>
                            <Autocomplete
                                sx={{ width: '100%' }}
                                size='small'
                                disableClearable
                                value={selectedType}
                                onChange={(event, value) => setSelectedType(value)}
                                getOptionLabel={(opt) => intl.formatMessage({ id: "POI." + poiTypes.find(type => type.key === opt).value })}
                                options={poiTypes.map(type => type.key)}
                                renderInput={(params) => <TextField margin="normal" {...params} label={<FormattedMessage id="POI.TYPE" />} />}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6}>
                        <TextField
                            autoFocus
                            size="small"
                            variant="outlined"
                            name="name"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            label={<span><FormattedMessage id="NAME" /> *</span>}
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                </Grid>
                <div style={{ height: '200px' }}>
                    <MapComponent className="pin-input" zoom={16} position={pinnedPosition} disableShapes>
                        <MapElementNew
                            key="map-elements"
                            markerSource={markerSource.current}
                            positionInput={position}
                            positionChange={setPosition}
                        />
                    </MapComponent>
                </div>

                <TextField
                    size="small"
                    variant="outlined"
                    multiline
                    rows="5"
                    name="description"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    label={<span><FormattedMessage id="DESCRIPTION" /> (<FormattedMessage id={"APP.LANGUAGE." + intl.locale} />)</span>}
                    fullWidth
                    margin="normal"
                />
                <PictureUploadButton
                    setFiles={setImage}
                    single
                />
            </DialogContent>
            <Divider variant="middle" sx={{ mx: 3 }} />
            <DialogActions sx={{ mx: 2 }}>
                <Button onClick={onClose}><FormattedMessage id="CANCEL" /></Button>
                <Button disabled={!name.length} onClick={onSubmit}><FormattedMessage id="SUBMIT" /></Button>
            </DialogActions>
        </Dialog >
    </>
}