import React, { useEffect, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { selectDateRange } from 'redux/dateRangeSlice';
import { selectResourcesTypesConf } from 'redux/configurationSlice';
import { useNavigate } from "react-router-dom";
import { MultiAxisLineChart } from 'charts'
import { AggregationTabs, LoadingData, AlertSnackbar } from 'components';

import { Box, Card, CardContent, CardHeader, IconButton, Typography, Tooltip } from '@mui/material';
import { OpenInNewOutlined } from '@mui/icons-material';

import API from 'api';



function MeasurementsTrendGraph(props) {
  const { title, attribution, locationId, resourceTypes, sampleTypes, aggregation, cardLink } = props;
  const { token } = useSelector(userSelector);
  const intl = useIntl();
  const navigate = useNavigate();
  const selectedDateRange = useSelector(selectDateRange);
  const resourceTypesConf = useSelector(selectResourcesTypesConf);
  const [data, setData] = useState(null);
  const [agr, setAgr] = useState({ group: 'NO_GROUP', time: null });
  const [alert, setAlert] = useState({ open: false });

  const onAlertClose = () => setAlert({ ...alert, open: false });

  useEffect(() => {
    if (aggregation) setAgr(aggregation);
    else return;
  }, [aggregation, setAgr]);

  const handleAggregationChange = (time) => {
    if (aggregation) return;
    else if (time === "h") setAgr({ group: 'PER_HOUR', time: 'h' });
    else if (time === "d") setAgr({ group: 'PER_DAY', time: 'd' });
    else if (time === "w") setAgr({ group: 'PER_WEEK', time: 'w' });
    else if (time === "m") setAgr({ group: 'PER_MONTH', time: 'm' });
    else setAgr({ group: 'NO_GROUP', time: null });;
  }

  const convertData = useCallback((apiData) => {
    if (resourceTypesConf) {
      apiData.forEach(dataSerie => {
        const labelId = resourceTypesConf.find(type => type.key === dataSerie._id.label).name;
        dataSerie.key = dataSerie._id.label;
        dataSerie.label = intl.formatMessage({ id: labelId });
      });
    }
    setData({ datasets: apiData });
  }, [setData, intl, resourceTypesConf]);

  useEffect(() => {
    API.measurements.getMultiTypeMeasurements(token, locationId, resourceTypes, selectedDateRange.dateFrom, selectedDateRange.dateTo, sampleTypes, agr.time).then(items => {
      if (items.data && items.data.length) {
        // store data in state
        convertData(items.data);
      }
      else setData({});
    }).catch(error => {
      setData({ error: error });
      setAlert({ open: true, message: error.data, severity: "error" });
      console.error("Error fetching data for MeasurementsTrendGraph, reason: ", error);
    });
  }, [token, locationId, resourceTypes, selectedDateRange, sampleTypes, agr, convertData]);

  const renderChart = useCallback(() => {
    if (data !== null && data.datasets) return (
      <MultiAxisLineChart name={"trendLineChart_" + locationId} data={data} aggregation={agr.time} locationId={locationId} />
    );

    if (data === null) return (
      <LoadingData noText size={2} />
    );
    else if (data.error) return (
      <Typography variant='body2'><FormattedMessage id="NO_DATA" /></Typography>
    );
    else return (
      <Typography variant='body2'><FormattedMessage id="NO_DATA_TIME_PERIOD" /></Typography>
    );
  }, [data, agr, locationId])

  return <>
    <AlertSnackbar noAnchor open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message} />
    <Card sx={{ width: '100%' }}>
      <CardHeader
        title={title}
        subheader={<FormattedMessage id={"GROUPING." + agr.group} />}
        action={
          !cardLink ?
            <AggregationTabs
              disabled={aggregation || data === null || data.error || !data.datasets}
              values={["h", "d", "w"]}
              setAggregation={handleAggregationChange}
            />
            : <Tooltip title={<FormattedMessage id={"LOCATION.OPEN"} />} key="details" position="left" arrow>
              <IconButton name="info" onClick={() => navigate('/locations/details/' + locationId)}>
                <OpenInNewOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
        }
      />
      <CardContent sx={{ cursor: 'pointer' }}>
        {renderChart()}
      </CardContent>
      {attribution && <Box sx={{ px: 2, pb: 1 }}>
        {attribution}
      </Box>}
    </Card>
  </>

}

export default React.memo(MeasurementsTrendGraph);