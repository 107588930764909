import React from 'react';
import { Toolbar, Drawer, Card, CardHeader, CardContent, Box, IconButton, useMediaQuery, Button } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const SideDrawer = (props) => {
    const { state, open, deleteButton } = props;
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    return (
        <Drawer
            anchor={smallScreen ? 'top' : 'right'}
            open={open}
            onClose={() => props.toggleDrawer()}
            PaperProps={{
                sx: {
                    backgroundImage: 'none'
                }
            }}
        >
            <Box minWidth={smallScreen ? '100%' : 320} maxWidth={420} width={smallScreen ? '100%' : null} sx={{ overflowX: 'hidden' }}>
                <Toolbar />
                <Card sx={{ backgroundImage: 'none' }}>
                    <CardHeader title={state.title}
                        subheader={state.subtitle}
                        action={
                            <IconButton onClick={() => props.toggleDrawer()}>
                                <CloseOutlined name="cross" />
                            </IconButton>
                        }
                     />

                    <CardContent sx={{ width: '100%' }}>
                        {state.display}
                    </CardContent>
                    {deleteButton ? 
                        <Button sx={{width: '100%', mb: 1}} onClick={() => props?.handleDeleteItem()} color='error'>
                            <FormattedMessage id="DELETE" /> 
                        </Button> 
                    : null }
                </Card>
            </Box >
        </Drawer >
    );
}

export default SideDrawer;