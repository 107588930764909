import { Typography, Link } from '@mui/material';



export default function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{ py: 0.5 }}>
            <Link color="inherit" href={process.env.REACT_APP_COPYRIGHT_URL}>
            {process.env.REACT_APP_TITLE}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};