import instance, { setAuthHeader } from './axiosEnv';

const createFormDataHelper = (newReportObject, imageFiles) => {
    const formData = new FormData();
    for (const key in newReportObject) {
        if (typeof newReportObject[key] !== 'string') {
            formData.append(key, JSON.stringify(newReportObject[key]));
        }
        else formData.append(key, (newReportObject[key]));
    }
    if (imageFiles) for (const file of imageFiles) {
        formData.append('pictures[]', file, file.name);
    }

    return formData;
}

const createIncidentReport = (token, newReportObject, imageFiles) => {
    const config = setAuthHeader(token);
    return instance.post('/reports', createFormDataHelper(newReportObject, imageFiles), config);
}

const reportAPI = {
    // create new staging location
    createIncidentReport,
}

export default reportAPI;
