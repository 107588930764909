import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "redux/appSlice";
import { Avatar, Tooltip, Menu, Stack, MenuItem, Typography } from '@mui/material';
import { languages } from "lang";

function LanguageAvatar(props) {
    const languageList = {};
    Object.keys(languages).forEach(lang => {
        if (lang === 'en') languageList['gb'] = languages[lang][`LANGUAGE.${lang}`];
        else languageList[lang] = languages[lang][`LANGUAGE.${lang}`];
    })
    return <Tooltip title={languageList[props.lang]} placement="left" arrow>
        <Avatar onClick={props.onClick} sx={theme => ({ width: 40, height: 35, cursor: 'pointer', border: `2px solid ${props.active ? theme.palette.primary.main : 'transparent'}` })} alt={props.lang} src={`https://raw.githubusercontent.com/hampusborgos/country-flags/main/png250px/${props.lang}.png`} />
    </Tooltip>
}

export default function LanguageSelect(props) {
    const { openLang, setOpenLang, anchorLang } = props;
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const handleLangChange = (lang) => {
        setOpenLang(false);
        dispatch(setLanguage(lang))
    }

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorLang}
            open={openLang}
            onClose={() => setOpenLang(false)}
        >
            {Object.keys(languages).map((lang, index) => (
                <MenuItem key={index} onClick={() => handleLangChange(lang)}>
                    <Stack spacing={2} direction='row' justifyContent="center" alignItems="center">
                        <LanguageAvatar active={app.lang === lang} lang={lang === 'en' ? 'gb' : lang} onClick={() => handleLangChange(lang)} />
                        <Typography ><FormattedMessage id={"LANGUAGE." + lang} /></Typography>
                    </Stack>
                </MenuItem>

            ))}

        </Menu>

    );
};