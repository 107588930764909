import { useEffect, useState, useCallback } from "react";
import { FormattedMessage } from 'react-intl';
import { MapComponent, NewPoIDialog, MARKERS } from "components";
import { useSelector } from "react-redux";
import { permissionSelector, userSelector } from "redux/userSlice";
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { toLonLat, fromLonLat } from 'ol/proj';
// import { Vector as VectorSource } from 'ol/source.js';
// import { Vector as VectorLayer } from 'ol/layer';
import { Avatar, SpeedDial, SpeedDialAction, SpeedDialIcon, Tooltip, useTheme } from "@mui/material";
import { /*AddLocationAltOutlined*/ ExploreOutlined, ExploreOffOutlined, /*LayersOutlined, LayersClearOutlined,*/ Settings, SettingsOutlined, /*CancelOutlined*/ } from '@mui/icons-material';
import API from 'api';




export default function LocationMapTab(props) {
    const { location, poiInput, setPoiInput, noInput } = props;
    const theme = useTheme();
    const { token } = useSelector(userSelector);
    // const poiAdmin = useSelector((state) => permissionSelector(state, 'create-poi'));
    const poiViewer = useSelector((state) => permissionSelector(state, 'view-poi'));
    const [toggleControl, setToggleControl] = useState(false);
    const [newPoIdialog, setNewPoIdialog] = useState(false);
    const [position, setPosition] = useState([]);
    const [hideGeoData, setHideGeoData] = useState(true);
    const [pointsOfInterest, setPointsOfInterest] = useState([]);

    useEffect(() => {
        if (poiViewer) API.poi.getLocationPoints(token, location._id).then(items => {
            if (items.data) setPointsOfInterest(items.data);
            else setPointsOfInterest([]);
        }).catch((e) => {
            console.error("Get Location Points of Interest has failed, reason: ", e);
            setPointsOfInterest([]);
        })
    }, [newPoIdialog, poiViewer, token, location]);

    useEffect(() => {
        if (!newPoIdialog) setPosition([]);
    }, [newPoIdialog, setPosition]);


    const renderMap = useCallback(() => {
        // const togglePoIinput = () => {
        //     if (!poiInput) setToggleControl(false);
        //     setPoiInput(!poiInput);
        // }

        const toggleMapControl = () => {
            setToggleControl(!toggleControl);
        }

        const onClick = (e) => {
            setHideGeoData(true);
            setPoiInput(false);
            setPosition(toLonLat(e.coordinate));
            setNewPoIdialog(true);
        };

        // const toggleGeoData = () => {
        //     setHideGeoData(!hideGeoData);
        // }

        const poiFeatures = pointsOfInterest.map((poi) => {
            return new Feature({
                geometry: new Point(fromLonLat(poi.position)),
                name: poi.name,
                type: "POI",
                object: poi
            });
        });
        poiFeatures.forEach((feature, index) => feature.setStyle(MARKERS.pointOfInterest(theme.palette.mode, pointsOfInterest[index].type)));

        const stagingLocationPin = new Feature({
            geometry: new Point(fromLonLat(location.position)),
            name: location.name,
            type: "marker",
            object: location
        });
        stagingLocationPin.setStyle(MARKERS.locationStaging(theme.palette.mode, true))

        return <MapComponent className={poiInput ? 'pin-input' : null} key={poiFeatures.length} disableInteraction={!toggleControl} disableShapes={hideGeoData} onClick={poiInput ? onClick : null} position={location.position} zoom={15} customFeatures={location.addedBy ? [stagingLocationPin] : []} location={location}>
            <div className="map-control" style={{ display: 'inherit', position: 'relative' }}>
                {/* IMPORTANT SX - SpeedDial sx.top value should be adjusted based on the number of SpeedDial's children. Eg. 3 SpeedDialActions require the value of sx.top: 150 for the current parent div height; sx.top: 210 at 2 SpeedDialActions */}
                {(poiInput !== undefined || !noInput) && <SpeedDial sx={{ position: 'absolute', top: 260, left: 8 }} ariaLabel="MapControls" direction="up" icon={<SpeedDialIcon openIcon={<Settings />} icon={<SettingsOutlined />} />}>
                    {/* {poiAdmin && poiAdmin.includes(location._id) ? <SpeedDialAction
                                    onClick={togglePoIinput}
                                    key={"new-poi"}
                                    icon={!poiInput ? <AddLocationAltOutlined /> : <CancelOutlined />}
                                    tooltipTitle={poiInput ? <FormattedMessage id="CANCEL" /> : <FormattedMessage id="ADD_POI" />}
                                    tooltipPlacement="right"
                                /> : null} */}
                    <SpeedDialAction
                        onClick={toggleMapControl}
                        key={"popups-toggle-map"}
                        icon={toggleControl ? <ExploreOffOutlined /> : <ExploreOutlined />}
                        tooltipTitle={toggleControl ? <FormattedMessage id="MAP_CONTROL.OFF" /> : <FormattedMessage id="MAP_CONTROL.ON" />}
                        tooltipPlacement="right"
                    />
                    {/* <SpeedDialAction
                        onClick={toggleGeoData}
                        key={"popups-toggle-geoData"}
                        icon={hideGeoData ? <LayersOutlined /> : <LayersClearOutlined />}
                        tooltipTitle={hideGeoData ? <FormattedMessage id="GEO_DATA.ON" /> : <FormattedMessage id="GEO_DATA.OFF" />}
                        tooltipPlacement="right"
                    /> */}
                </SpeedDial>}
            </div>
            <div className="map-control-info" style={{ display: toggleControl ? 'inherit' : 'none', position: 'relative', cursor: 'help' }}>
                <Tooltip title={<FormattedMessage id="MAP_CONTROL" />} placement="right" arrow>
                    <Avatar variant="rounded" sx={{ position: 'absolute', top: 60, left: 12, zIndex: 9, width: 32, height: 32, bgcolor: theme.palette.secondary.dark }} >
                        <ExploreOutlined color={theme.palette.secondary.light} />
                    </Avatar>
                </Tooltip>
            </div>
        </MapComponent>
    }, [pointsOfInterest, hideGeoData, poiInput, location, toggleControl, setPoiInput, noInput, theme]);

    return <div style={{ height: '400px' }} >
        {renderMap()}

        {!!position.length && <NewPoIDialog open={newPoIdialog} onClose={() => setNewPoIdialog(false)} location={location} pinnedPosition={position} />}
    </div>;
}