import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { PictureUploadButton, NewStagingLocationDialog, AlertSnackbar } from 'components';
import { selectReportTypesConf } from "redux/configurationSlice";
import { selectAllMeasurePoints } from "redux/locationsSlice";
import { userSelector } from "redux/userSlice";
import { selectMapProps } from "redux/mapSlice";
import { Divider, Stack, Button, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, Autocomplete, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { AddOutlined, PendingActionsOutlined } from '@mui/icons-material';
import API from 'api';
import { imageResize } from "utils";

const maxWidth = 2600
const maxHeight = 1500

export default function IncidentReportDialog(props) {
    const { open, onClose, onSubmit } = props;
    const fullScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const { token } = useSelector(userSelector);
    const mapProps = useSelector(selectMapProps);
    const reportTypes = useSelector(selectReportTypesConf);
    const locations = useSelector(selectAllMeasurePoints);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [incidentType, setIncidentType] = useState(null);
    const [openNewLocation, setOpenNewLocation] = useState(false);
    const [description, setDescription] = useState("");
    const [files, setFiles] = useState(null);
    const [submittedFlag, setSubmittedFlag] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const intl = useIntl();
    const [descLimitOverFlow, setDescLimitOverflow] = useState(false);


    useEffect(() => {
        if (description.length > 1500) {
            setDescLimitOverflow(true);
            setAlert({ open: true, message: { id: "LOCATION_DESCRIPTION_OVERFLOW" }, severity: "error" });
        }
        else {
            setDescLimitOverflow(false);
            setAlert({ open: false })
        }

    }, [description])

    const resetForm = () => {
        setSelectedLocation(null);
        setIncidentType(null);
        setDescription("");
        setFiles(null);
        setSubmittedFlag(false);
    }

    const closeDialogHandler = () => {
        resetForm();
        onClose();
    }

    const onSubmitHandler = () => {
        setSubmittedFlag(true);
        const newReport = {
            locationId: selectedLocation._id,
            reportType: parseInt(incidentType),
            description
        };

        let updatedFiles = []
        async function resizeImageHelper() {
            try {
                for (const item of files) {
                    const resizedImage = await imageResize(item, maxWidth, maxHeight);
                    updatedFiles.push(resizedImage)
                }
            } catch (error) {
                console.error("Error compressing image: ", error);
            }
        }
        async function processSubmission() {
            if (files !== null) await resizeImageHelper();

            API.reports.createIncidentReport(token, newReport, updatedFiles).then((items) => {
                onSubmit("ADDED");
                closeDialogHandler();
            }).catch(error => {
                setSubmittedFlag(false);
                setAlert({ open: true, message: error.data, severity: "error" });
                console.error("Error posting new incident report:", error);
            })
        }

        processSubmission()
    }


    return <>
        <AlertSnackbar onClose={() => setAlert({ ...alert, open: false })} open={alert.open} severity={alert.severity} message={alert.message} />
        <NewStagingLocationDialog noSaveOption open={openNewLocation} onClose={() => setOpenNewLocation(false)} pinnedPosition={[mapProps.defaultY, mapProps.defaultX]} onSubmit={onSubmit} />
        <Dialog
            open={open}
            fullScreen={fullScreen}
            fullWidth maxWidth='sm'
            aria-labelledby="new-location-form"
            scroll="paper"
        >
            <DialogTitle>
                <FormattedMessage id="INCIDENT_REPORT.NEW_TITLE" />
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <div>
                        <Autocomplete
                            required={!selectedLocation}
                            id="selectLocation"
                            options={locations}
                            getOptionLabel={(location) => location.name}
                            renderOption={({ touchRippleRef, ...props }, location) => <ListItem {...props}>
                                {location.addedBy && <ListItemIcon>
                                    <Tooltip title={<FormattedMessage id={"STAGING.PENDING." + (!location.reviewedBy ? "REVIEW" : "APPROVE")} />} arrow>
                                        <PendingActionsOutlined fontSize="small" color={!location.reviewedBy ? "warning" : "info"} />
                                    </Tooltip>
                                </ListItemIcon>}
                                <ListItemText inset={Boolean(!location.addedBy && locations.filter(el => el.addedBy).length)} primary={location.name} />

                            </ListItem>}
                            value={selectedLocation}
                            onChange={(e, location) => setSelectedLocation(location)}
                            renderInput={(params) => (
                                <TextField {...params} key={locations.map(location => location._id)} label={<span><FormattedMessage id="ALL_LOCATIONS" />{!selectedLocation ? " *" : null}</span>} />
                            )}
                            sx={{ pt: 1, width: '100%' }}
                            size="small"
                            fullWidth
                        />
                        <Button disabled={selectedLocation !== null} startIcon={<AddOutlined />} onClick={() => setOpenNewLocation(true)}><FormattedMessage id="CREATE_STAGING_LOCATION" /></Button>
                    </div>

                    <FormControl required={!incidentType}>
                        <FormLabel id="radio-buttons-group-label"><FormattedMessage id="INCIDENT_REPORT.SELECT_TYPE" /></FormLabel>
                        <RadioGroup
                            aria-labelledby="radio-buttons-group-label"
                            value={incidentType}
                            onChange={(e) => setIncidentType(e.target.value)}
                            name="radio-buttons-group"
                        >
                            {reportTypes.map(type => <FormControlLabel key={type.key} value={type.key} control={<Radio />} label={<FormattedMessage id={"INCIDENT_REPORT_TYPE_" + type.value} />} />)}
                        </RadioGroup>
                    </FormControl>

                    <TextField
                        required={!description.length}
                        multiline
                        rows="3"
                        name="description"
                        label={<FormattedMessage id="DESCRIPTION" />}
                        placeholder={intl.formatMessage({ id: "DESCRIPTION_PLACEHOLDER" })}
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <PictureUploadButton setFiles={setFiles} />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button color="warning" variant="text" onClick={closeDialogHandler}>
                    <FormattedMessage id="CANCEL" />
                </Button>
                <Button disabled={submittedFlag || !(selectedLocation && incidentType && description.length) || descLimitOverFlow} variant="text" onClick={onSubmitHandler}>
                    <FormattedMessage id="SUBMIT" />
                </Button>
            </DialogActions>
        </Dialog>
    </>
}