import { FormattedMessage } from "react-intl";
import { CircularProgress, Stack, Typography } from "@mui/material";


export default function LoadingData(props) {
    const { noText, size, children } = props;
    return <Stack direction="row" spacing={1} gap={1} alignItems='center'>
        <CircularProgress size={size ? size + 'rem' : '1rem'} />
        {!noText || !children
            ? <Typography>
                <FormattedMessage id="LOADING_DATA" />
            </Typography>
            : null
        }
        {children}
    </Stack>;
}