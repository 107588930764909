import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { selectDateRange, setDatesRange, setNamedRange } from 'redux/dateRangeSlice';
import { Avatar, Badge, Box, Button, TextField, IconButton, ButtonGroup, Tooltip } from '@mui/material';
import { TodayOutlined } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, hr } from "date-fns/locale";

const DateRangeSelector = (props) => {
    const { column } = props;
    const selectedDateRange = useSelector(selectDateRange);
    const { rangeName } = selectedDateRange;
    const intl = useIntl();
    const dispatch = useDispatch();

    const changeDateFrom = useCallback((value) => {
        const startDate = new Date(value);
        if (value && (startDate.getTime() < selectedDateRange.dateTo)) {
            dispatch(setDatesRange({
                dateFrom: startDate.getTime(),
                dateTo: selectedDateRange.dateTo
            }))
        }
        else if (value && (startDate.getTime() >= selectedDateRange.dateTo)) {
            dispatch(setDatesRange({
                dateFrom: startDate.getTime(),
                dateTo: startDate.getTime() + 1
            }))
        }
    }, [selectedDateRange, dispatch]);

    const changeDateTo = useCallback((value) => {
        const endDate = new Date(value);
        if (value && (endDate.getTime() > selectedDateRange.dateFrom)) {
            dispatch(setDatesRange({
                dateFrom: selectedDateRange.dateFrom,
                dateTo: endDate.getTime()
            }))
        }
        else if (value && (endDate.getTime() <= selectedDateRange.dateFrom)) {
            dispatch(setDatesRange({
                dateFrom: endDate.getTime() - 1,
                dateTo: endDate.getTime()
            }))
        }
    }, [selectedDateRange, dispatch]);

    const changeToToday = useCallback(() => {
        const date = new Date().getTime();
        dispatch(setDatesRange({
            dateFrom: date,
            dateTo: date
        }))
    }, [dispatch]);

    const renderButtons = useCallback(() => {
        return <>
            {column ? <Badge badgeContent={
                <Tooltip arrow title={<FormattedMessage id="TODAY" />}>
                    <IconButton color="primary" onClick={changeToToday}>
                        <Avatar sx={{ width: 25, height: 25, bgcolor: selectedDateRange.dateFrom === selectedDateRange.dateTo ? 'primary.main' : 'default' }}><TodayOutlined fontSize="small" /></Avatar>
                    </IconButton>
                </Tooltip>
            } />
                : <Button
                    name='btnToday' key="today"
                    sx={{ textTransform: 'none' }}
                    onClick={changeToToday}
                    variant={selectedDateRange.dateFrom === selectedDateRange.dateTo ? 'contained' : 'outlined'}
                >
                    <FormattedMessage id="TODAY" />
                </Button>}
            <Button
                name='btnWeek' key="week"
                sx={{ textTransform: 'none' }}
                onClick={() => dispatch(setNamedRange('week'))}
                variant={rangeName === 'week' ? 'contained' : 'outlined'}
            >
                <FormattedMessage id="GROUPING.WEEK" />
            </Button>
            <Button
                name='btnMonth' key="month"
                sx={{ textTransform: 'none' }}
                onClick={() => dispatch(setNamedRange('month'))}
                variant={rangeName === 'month' ? 'contained' : 'outlined'}
            >
                <FormattedMessage id="GROUPING.MONTH" />
            </Button>
            <Button
                name='btnYear' key="year"
                sx={{ textTransform: 'none' }}
                onClick={() => dispatch(setNamedRange('year'))}
                variant={rangeName === 'year' ? 'contained' : 'outlined'}
            >
                <FormattedMessage id="YEAR" />
            </Button>
        </>
    }, [rangeName, changeToToday, dispatch, selectedDateRange, column])

    return (
        <Box sx={{ display: 'flex', flexDirection: column ? 'column' : 'row', gap: '8px', pt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                <DatePicker
                    label={intl.formatMessage({ id: rangeName === 'week' || rangeName === 'month' ? "DATE_FROM" : "DATE_SELECTED" })}
                    value={new Date(selectedDateRange.dateFrom)}
                    onChange={changeDateFrom}
                    renderInput={(params) => <TextField {...params} size='small' />}
                />
                <DatePicker
                    label={intl.formatMessage({ id: "DATE_TO" })}
                    value={new Date(selectedDateRange.dateTo)}
                    onChange={changeDateTo}
                    renderInput={(params) => <TextField {...params} size='small' sx={{ ...(column && { mt: 1 }) }} />}
                />
            </LocalizationProvider>

            {column ?
                <ButtonGroup fullWidth sx={{ ...(column && { mt: 1 }) }}>
                    {renderButtons()}
                </ButtonGroup>
                : renderButtons()
            }
        </Box>
    );
}

export default DateRangeSelector;