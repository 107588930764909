import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectStatusCodesTypesConf, selectResourcesTypesConf, selectResourceTypeGroupsConf } from "redux/configurationSlice";
import { updateLocationStatusCode } from "redux/locationsSlice";
import { userSelector } from "redux/userSlice";
import { PictureUploadButton } from "components";
import { Collapse, Box, Button, TextField, Typography, useTheme, useMediaQuery, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ExpandMore } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, hr } from "date-fns/locale"
import API from "api";

const ExpandMoreCustom = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} color="primary" >
        <ExpandMore />
    </IconButton>;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


export default function AddSampleForm(props) {
    const { location, setReportData } = props;
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const user = useSelector(userSelector);
    const statusCodeTypes = useSelector(selectStatusCodesTypesConf);
    const resourceTypesConf = useSelector(selectResourcesTypesConf);
    const manualResourceTypes = useSelector(selectResourceTypeGroupsConf).filter(el => el.name !== "SERVICE" && el.name !== "SENSOR");
    const resourceTypes = resourceTypesConf.filter(resource => location.resourceTypes.includes(resource.key) && manualResourceTypes.flatMap(el => el.resourceTypes).includes(resource.key) && !resource.name.includes("MANUAL"))
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialValues = Object.fromEntries(new Map([...resourceTypes.map(el => [el.key.toString(), '']), ['notes', ''], ['timestamp', new Date()]]));
    const [errorMsg, setErrorMsg] = useState(null);
    const [files, setFiles] = useState(null);
    const [images, setImages] = useState([]);
    const [expanded, setExpanded] = useState(manualResourceTypes.map((el, i) => true));

    const validationSchema = resourceTypes.map(resource => {
        return [
            resource.key.toString(),
            Yup.number().typeError(intl.formatMessage({ id: 'TYPE_ERROR' }))
                .min(resource.range.min, `${intl.formatMessage({ id: 'MIN_ERROR' })} ${resource.range.min}`)
                .max(resource.range.max, `${intl.formatMessage({ id: 'MAX_ERROR' })} ${resource.range.max}`)
        ];
    });
    validationSchema.push([
        'notes',
        Yup.string().typeError(intl.formatMessage({ id: 'TYPE_ERROR' }))
            .max(256, intl.formatMessage({ id: 'NOTES_ERROR' }))
    ]);
    validationSchema.push([
        'timestamp',
        Yup.date().typeError(intl.formatMessage({ id: 'DATE_ERROR' }))
    ]);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object(Object.fromEntries(validationSchema)),
        onReset: (values) => {
            props.setSelectedLocation(null);
            navigate('/sampleEntry');
        },
        onSubmit: (values) => {
            let dataValues = {};
            for (let index = 0; index < Object.keys(values).length; index++) {
                const key = Object.keys(values)[index];
                // find only resources that are configured for location
                if (location.resourceTypes.includes(parseInt(key))) Object.assign(dataValues, { [key]: values[key] });
            }
            if (Object.values(dataValues).some(el => el !== "") || values.notes !== "") {

                Object.assign(dataValues, { 'notes': values.notes, 'timestamp': new Date(values.timestamp).getTime() });

                // token, collector, locationId, values, files
                API.measurements.postMeasurements(user.token, user.email, location._id, dataValues, files).then(({ data }) => {
                    if (statusCodeTypes.length) {
                        // dispatch action to update statusCodes for location and LocationGroup
                        dispatch(updateLocationStatusCode({
                            locationId: location._id,
                            locationGroupId: location.locationGroupId,
                            locationStatusCode: data.locationStatusCode || [],
                            groupStatusCode: data.groupStatusCode || []
                        }));
                    }
                    // trigger change view to Report
                    setReportData({ ...data, notes: values.notes, timestamp: values.timestamp, collector: user.email, locationId: location._id, pictures: images });
                }).catch((e) => {
                    if (e.status === 403) setErrorMsg(<FormattedMessage id="ACCESS_DENIED" />);
                    else setErrorMsg(e.data.message || "");
                });
            }
            else setErrorMsg(<FormattedMessage id="EMPTY_VALUES" />);
        }
    });

    useEffect(() => {
        setErrorMsg("");
    }, [formik.values])

    const renderForm = useCallback(() => {

        if (!location) return <Box><FormattedMessage id="NO_DATA" /></Box>;
        else return (
            <Box component="form" onSubmit={formik.handleSubmit}>
                {errorMsg ? <Typography sx={{ textAlign: 'center', mb: 2, color: theme.palette.error.main }}>{errorMsg}</Typography> : null}
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                    {smallScreen ? <MobileDateTimePicker
                        label={<FormattedMessage id="TIMESTAMP" />}
                        value={formik.values['timestamp']}
                        onChange={(e) => formik.setFieldValue('timestamp', new Date(e))}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth error={Boolean(formik.errors['timestamp'])}
                            helperText={formik.errors['timestamp']} />}
                    /> : <DateTimePicker
                        label={<FormattedMessage id="TIMESTAMP" />}
                        value={formik.values['timestamp']}
                        onChange={(e) => formik.setFieldValue('timestamp', new Date(e))}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth error={Boolean(formik.errors['timestamp'])}
                            helperText={formik.errors['timestamp']} />}
                    />}
                </LocalizationProvider>

                {manualResourceTypes.map((resGroup, index) => {
                    return <div>
                        <span style={{ cursor: 'pointer' }} onClick={() => setExpanded(manualResourceTypes.map((el, i) => i === index ? !expanded[i] : expanded[i]))}>
                            <Typography sx={{ pt: 2, display: 'inline-block' }} key={resGroup._id} color="primary" variant="h6"><FormattedMessage id={"RESOURCE_TYPE.GROUP." + resGroup.name} /></Typography>
                            <ExpandMoreCustom sx={{ mb: 1 }} expand={expanded[index]} />
                        </span>
                        <Collapse in={expanded[index]}>
                            {
                                resourceTypes.filter(res => resGroup.resourceTypes.includes(res.key)).map(resource =>
                                    <TextField
                                        sx={{ p: 0, mt: 0 }}
                                        key={resource.key}
                                        type="text"
                                        name={resource.key.toString()}
                                        label={<FormattedMessage id={resource.name} />}
                                        value={formik.values[resource.key.toString()]}
                                        onChange={formik.handleChange}
                                        placeholder={intl.formatMessage({ id: 'DATA_PLACEHOLDER' }) + ` (${resource.range.min} - ${resource.range.max})`}
                                        error={Boolean(formik.errors[resource.key.toString()])}
                                        helperText={formik.errors[resource.key.toString()]}
                                        margin="normal"
                                        variant="standard"
                                        size="small"
                                        fullWidth
                                    />

                                )
                            }
                        </Collapse>
                    </div>
                })}

                <Typography sx={{ pt: 2 }} color="primary" variant="h6"><FormattedMessage id="NOTES_AND_PICTURES" /></Typography>
                <TextField
                    multiline
                    rows="3"
                    name="notes"
                    label={<FormattedMessage id="NOTES" />}
                    placeholder={intl.formatMessage({ id: "NOTE_PLACEHOLDER" })}
                    value={formik.values['notes']}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors['notes'])}
                    helperText={formik.errors['notes']}
                    margin="normal"
                    variant="standard"
                    fullWidth
                />

                <Box sx={{ my: 3 }}>
                    <PictureUploadButton
                        setFiles={setFiles}
                        setImages={setImages}
                    />
                </Box>

                <Box sx={{ my: 2, position: 'absolute', bottom: '0%', right: smallScreen ? '16px' : '40px' }}>
                    <Button onClick={formik.handleReset} ><FormattedMessage id="CANCEL" /></Button>
                    <Button disabled={Object.keys(formik.errors).length ? true : false || errorMsg ? true : false} type="submit"><FormattedMessage id="SUBMIT" /></Button>
                </Box>
            </Box>
        )
    }, [errorMsg, intl, location, resourceTypes, theme.palette, formik, smallScreen, expanded, manualResourceTypes]);

    return renderForm();
}