import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'api';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
  filters: {
    // filter for quality types (key in array are FILTERED_OUT!)
    statusCodes: [],
    // filter for location/measure point types (key in array are FILTERED_OUT!)
    locationTypes: [],
    // filter for location/measure point attraction types (key in array are FILTERED_OUT!)
    attractionTypes: [],
    // filter for location/measure point accessibility option types (key in array are FILTERED_OUT!)
    accessibilityOptionTypes: [],
    // method for filtering locations by accessibility types (some OR every)
    accessibilityOptionMethod: "some",
    // filter for location/measure point tourism option types (key in array are FILTERED_OUT!)
    tourismOptionTypes: [],
    // method for filtering locations by tourism types (some OR every)
    tourismOptionMethod: "some",
  }
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchLocations = createAsyncThunk('locations/fetchLocations', async (token) => {
  const { getAllLocationData, getAllLocations } = API.locations;
  const getLocations = token === null ? getAllLocations : getAllLocationData;
  const response = await getLocations(token);

  // The value we return becomes the `fulfilled` action payload
  const filteredResponse = response.data.filter(x => x._id === process.env.REACT_APP_LOCATION_ID || x.locationGroupId === process.env.REACT_APP_LOCATION_ID);
  if(filteredResponse.length)
    return filteredResponse

  else
    return (await getAllLocations(token)).data.filter(x => x._id === process.env.REACT_APP_LOCATION_ID || x.locationGroupId === process.env.REACT_APP_LOCATION_ID);
}
);

export const locationSlice = createSlice({
  name: 'locations',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    updateLocationState: (state, action) => {

      // state.data is current state that we will modify
      // action.payload is our data object returned from db after update

      // 1. Update locationGroup info for its MP
      const locationGroupIndex = state.data.findIndex(el => el._id === action.payload.locationGroupId);
      // if locationGroup existed before - we are updating location so this must be true
      if (locationGroupIndex > -1) {
        const mpIndex = state.data[locationGroupIndex].measurePoints.findIndex(el => el._id === action.payload._id)
        // if mp existed before on that locationGroup, if not we need to create it
        if (mpIndex > -1) {
          state.data[locationGroupIndex].measurePoints[mpIndex] = {
            _id: action.payload._id,
            name: action.payload.name,
            measurePointType: action.payload.measurePointType,
            locationGroupId: action.payload.locationGroupId,
            statusCode: action.payload.statusCode,
            position: action.payload.position
          };
        }
        else {
          state.data[locationGroupIndex].measurePoints.push({
            _id: action.payload._id,
            name: action.payload.name,
            measurePointType: action.payload.measurePointType,
            locationGroupId: action.payload.locationGroupId,
            statusCode: action.payload.statusCode,
            position: action.payload.position
          });
        }

        // 2. Update location in state:
        const locationIndex = state.data.findIndex(el => el._id === action.payload._id);
        // if location existed in data
        if (locationIndex > -1) {
          state.data[locationIndex] = {
            ...action.payload,
            connection: [
              [...state.data[locationGroupIndex].position],
              [...action.payload.position]
            ]
          };
        }
        else {
          state.data.push({
            ...action.payload,
            connection: [
              [...state.data[locationGroupIndex].position],
              [...action.payload.position]
            ]
          });
        }
      }
    },

    updateLocationStateDetails: (state, action) => {
      const locationIndex = state.data.findIndex(el => el._id === action.payload._id);
      if (locationIndex > -1) {
        state.data[locationIndex] = {
          ...action.payload,
          connection: [
            [...action.payload.position]
          ]
        };
      }
    },

    removeLocation: (state, action) => {
      const locationIndex = state.data.findIndex(el => el._id === action.payload._id);
      if (locationIndex > -1) {
        state.data.splice(locationIndex, 1)
      }
    },

    updateLocationGroupState: (state, action) => {
      const locationGroupIndex = state.data.findIndex(el => el._id === action.payload._id);
      // if location group already existed
      if (locationGroupIndex > -1) {
        // check if position changed 
        if (JSON.stringify(state.data[locationGroupIndex].position) !== JSON.stringify(action.payload.position)) {
          // update connections array
          for (const locationElem of state.data) {
            if (locationElem.locationGroupId === action.payload._id)
              locationElem.connection[0] = action.payload.position;
          }
        }
        state.data[locationGroupIndex] = {
          description: action.payload.description,
          picture: action.payload.picture,
          statusCode: action.payload.statusCode,
          position: action.payload.position,
          _id: action.payload._id,
          name: action.payload.name,
          measurePoints: state.data[locationGroupIndex].measurePoints
        }
      }
      else {
        state.data.push({
          ...action.payload,
          measurePoints: []
        })
      }
    },

    updateLocationStatusCode: (state, action) => {
      // { locationId, locationGroupId, groupStatusCode, locationStatusCode }
      // 1. Update locationGroup info for its MP
      const locationGroupIndex = state.data.findIndex(el => el._id === action.payload.locationGroupId);

      if (locationGroupIndex > -1) {
        // update locationGroup's status code
        state.data[locationGroupIndex].statusCode = action.payload.groupStatusCode;

        const mpIndex = state.data[locationGroupIndex].measurePoints.findIndex(el => el._id === action.payload.locationId)
        if (mpIndex > -1) {
          state.data[locationGroupIndex].measurePoints[mpIndex].statusCode = action.payload.locationStatusCode;
        }
      }
      // 2. Update location
      const locationIndex = state.data.findIndex(el => el._id === action.payload.locationId);
      if (locationIndex > -1) state.data[locationIndex].statusCode = action.payload.locationStatusCode;

    },

    /** Set date range by invididual dates  */
    toggleFilter: (state, action) => {
      const { filterType, filterValue } = action.payload;
      if (typeof filterValue !== 'object') {
        // find value in filter arrays
        const index = state.filters[filterType].indexOf(filterValue);
        // if index is found - remove it, if not found - add it
        (index > -1) ? state.filters[filterType].splice(index, 1) : state.filters[filterType].push(filterValue);
      }
      else {
        state.filters[filterType] = filterValue;
        // const foundIndex = state.filters[filterType].findIndex(el => el.key === filterValue.key && el.value === filterValue.value);
        // (foundIndex > -1) ?
        //   state.filters[filterType].splice(state.filters[filterType].findIndex(el => el.key === filterValue.key && el.value === filterValue.value), 1)
        //   : state.filters[filterType].push(filterValue)
      }
    },
    toggleFiltersMethod: (state, action) => {
      const { type, method } = action.payload ? action.payload : {};
      if (!type && !method) return state;
      else state.filters[type + "OptionMethod"] = method === "some" ? "some" : "every";
    },
    clearFilters: (state, action) => {
      const { type } = action.payload ? action.payload : {};
      if (!type) state.filters = initialState.filters;
      else state.filters[type] = initialState.filters[type]
      return state;
    }

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLocations.rejected, (state) => {
        console.error("Failed to load locations...");
        state.status = 'failed';
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.status = 'complete';
        state.data = action.payload;
      });
  },
});

export const { toggleFilter, toggleFiltersMethod, clearFilters, updateLocationState, updateLocationStateDetails, removeLocation, updateLocationGroupState, updateLocationStatusCode } = locationSlice.actions;

// Selectors

// get locations data root element (parent locations)
export const selectLocations = (state, locationId) => {
  const filterRef = (location) => !locationId ? location.locationGroupId === undefined : location._id === locationId && location.locationGroupId === undefined;
  return state.locations.data.filter(filterRef);
}

export const selectMeasurePoints = (state, locationId) => {
  const filterRef = (location) => !locationId ? location.locationGroupId === process.env.REACT_APP_LOCATION_ID : location._id === locationId && location.locationGroupId === process.env.REACT_APP_LOCATION_ID;
  return state.locations.data.filter(filterRef).filter(location => location.addedBy === undefined);
}

export const selectStagingMeasurePoints = (state) => {
  return state.locations.data.filter(location => location.addedBy !== undefined);
}

export const selectAllMeasurePoints = (state) => {
  return state.locations.data.filter(location => location.locationGroupId !== undefined);
}

export const selectGroupsMeasurePoints = (state, locationId) => {
  return state.locations.data.filter(location => location.locationGroupId === locationId);
}

// get locations and measure points data
export const selectAllLocations = (state) => state.locations.data;

// get locations and measure points data for specific location group
export const selectAllLogoLocations = (state) => state.locations.data.filter(location => location.logo !== undefined);

// get locations data filters
export const selectLocationFilters = (state) => state.locations.filters;

// get locations data filters array length
export const selectLocationOptionsFilters = (state) => state.locations.filters.accessibilityOptionTypes.length + state.locations.filters.tourismOptionTypes.length;

// get approved measure points matching active filters (beware: it is a negative match - locations with keys present in filter array will be ommited)
export const selectFilteredLocations = (state) => {
  return state.locations.data.filter(location => (
    location.locationGroupId !== undefined
    && location.addedBy === undefined
    && !state.locations.filters.locationTypes.includes(location.type)
    && !state.locations.filters.statusCodes.includes(location.statusCode)
    && !state.locations.filters.attractionTypes.includes(location.touristAttraction.attractionType)
    && (state.locations.filters.accessibilityOptionTypes.length ? state.locations.filters.accessibilityOptionTypes[state.locations.filters.accessibilityOptionMethod](type => location.touristAttraction.accessibilityOptions.includes(type)) : true)
    && (state.locations.filters.tourismOptionTypes.length ? state.locations.filters.tourismOptionTypes[state.locations.filters.tourismOptionMethod](type => location.touristAttraction.tourismTypes.includes(type)) : true)
  ))
};

// get single location from it's ID
export const selectLocationDetails = (state, locationId) => {
  return state.locations.data.find(location => location._id === locationId)
};

export const selectLocationGroup = (state, locationId) => {
  return state.locations.data.find(measurePoints => measurePoints.locationGroupId === locationId)
};

// find parent location (if exist) of location with provided ID
export const selectLocationParent = (state, locationId) => {
  //return state.locations.data.find( location => location.measurePoints && location.measurePoints.length);
  return state.locations.data.find(location => {
    if (location.measurePoints && location.measurePoints.length) {
      return location.measurePoints.some(mp => mp._id === locationId);
    }
    return false;
  });
};

export default locationSlice.reducer;