import React from 'react';
import { Container, Card, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { PictureGallery } from 'components';

const ReportDetails = (props) => {
    const { timestamp, locationName, user, notes, pictures } = props;

    const CustomText = (props) => {
        return <Stack direction='column'>
            <Typography variant='body2' color='primary.main'><FormattedMessage id={props.messageId} /></Typography>
            {props.component ? props.component : <Typography variant='body1'>{props.text}</Typography>}
        </Stack>
    };

    return <Container disableGutters>
        <Stack direction='column' spacing={1}>
            <CustomText messageId="TIMESTAMP" text={timestamp} />
            <CustomText messageId="LOCATION_NAME" text={locationName} />
            <CustomText messageId="STAGING.USER" text={user} />
            <CustomText messageId="INCIDENT_REPORT_NOTES" component={<Card variant='outlined' sx={{ p: 0.5, fontStyle: 'italic' }}>
                <Typography variant='body1'>{notes}</Typography>
            </Card>} />
            {!!pictures && !!pictures.length && <Stack direction='column'>
                <Typography color='primary.main' variant='body2'><FormattedMessage id='REPORT_PICTURES' /> ({pictures.length})</Typography>
                <PictureGallery maxWidth='300px' pictures={pictures.map(el => ({ name: el.split('/').slice(-1)[0], url: process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + el }))} />
            </Stack>}
        </Stack>
    </Container>;
}

export default ReportDetails;