import { FormattedMessage } from "react-intl";
import { Typography, useTheme, Link } from "@mui/material";


export default function OWAttribution(props) {
    const theme = useTheme();
    const fontSize = theme.typography.fontSize;
    const imagePath = theme.palette.mode === "light" ? "/images/OpenWeather.png" : "/images/OpenWeather-Negative.png"
    return <span>
        <Typography variant="caption">
            <FormattedMessage id="OWM.ATTRIBUTION" />
            <Link underline="hover" href="https://openweathermap.org/">OpenWeatherMap<img src={window.location.origin + imagePath} alt="OWlogo" width={fontSize * 4} height={fontSize * 2} /></Link>
        </Typography>
    </span>
}