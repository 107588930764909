import instance, { setAuthHeader } from './axiosEnv';

const getAllLocations = () => {
    return instance.get('/locations');
}

const getLocationById = (token, id) => {
    const config = setAuthHeader(token);
    return instance.get('/locations/staging/' + id, config);
}

const getStagingLocationsData = (token) => {
    if (!token) return Promise.resolve({ data: [] });
    const config = setAuthHeader(token);
    return instance.get('/locations/staging', config);
}

const getAllStagingLocationsData = (token, type) => {
    const config = setAuthHeader(token);
    return instance.get('/locations/staging/' + type, config);
}

const getStagingLocationCount = (token, role) => {
    const config = setAuthHeader(token);
    switch (role) {
        case "approve":
            return instance.get('/locations/staging/countApprove', config);
        case "review":
            return instance.get('/locations/staging/countReview', config);
        case "view":
            return instance.get('/locations/staging/countView', config);
        default:
            return null;
    }
}

const getAllLocationData = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/locations/all', config);
}

const getAllGeoFeatures = () => {
    return instance.get('/locations/geoFeatures');
}

const getAllGeoFeaturesData = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/locations/geoFeatures/all', config);
}

const getStatusCode = (locationId) => {
    return instance.get(`/locations/${locationId}/complianceIndex`);
}

const createFormDataHelper = (newLocationObject, imageFile) => {
    const formData = new FormData();
    for (const key in newLocationObject) {
        if (typeof newLocationObject[key] !== 'string') {
            formData.append(key, JSON.stringify(newLocationObject[key]));
        }
        else formData.append(key, (newLocationObject[key]));
    }
    if (imageFile) for (const file of imageFile) {
        formData.append('picture', file, file.name);
    }

    return formData;
}

const updateLocation = (token, locationId, newLocationObject, imageFile) => {
    const config = setAuthHeader(token);
    return instance.put('/locations/' + locationId, createFormDataHelper(newLocationObject, imageFile), config);
}

const updateLocationGroup = (token, locationGroupId, newLocationGroupObject, imageFile) => {
    const config = setAuthHeader(token);
    return instance.put('/locations/group/' + locationGroupId, createFormDataHelper(newLocationGroupObject, imageFile), config);
}

const updateUserStagingLocation = (token, newLocationObject, imageFile) => {
    const config = setAuthHeader(token);
    return instance.put('/locations/staging/update', createFormDataHelper(newLocationObject, imageFile), config);
}

const updateSubmitStagingLocation = (token, locationId, imageFile) => {
    const config = setAuthHeader(token);
    return instance.put('/locations/staging/submit/' + locationId, null, config);
}

const updateStagingLocation = (token, newLocationObject, imageFile) => {
    const config = setAuthHeader(token);
    return instance.put('/locations/staging/review/update', createFormDataHelper(newLocationObject, imageFile), config);
}

const updateReviewStagingLocation = (token, locationId) => {
    const config = setAuthHeader(token);
    return instance.put('/locations/staging/review/accept/' + locationId, null, config);
}

const updateApproveStagingLocation = (token, locationId) => {
    const config = setAuthHeader(token);
    return instance.put('/locations/staging/approve/' + locationId, null, config);
}

const createStagingLocation = (token, newLocationObject, imageFile) => {
    const config = setAuthHeader(token);
    return instance.post('/locations/staging', createFormDataHelper(newLocationObject, imageFile), config);
}

const createAndReviewStagingLocation = (token, newLocationObject, imageFile) => {
    const config = setAuthHeader(token);
    return instance.post('/locations/staging/review', createFormDataHelper(newLocationObject, imageFile), config);
}

const createLocation = (token, newLocationObject, imageFile) => {
    const config = setAuthHeader(token);
    return instance.post('/locations', createFormDataHelper(newLocationObject, imageFile), config);
}

const deleteLocation = (token, locationId) => {
    const config = setAuthHeader(token);
    return instance.delete('/locations/' + locationId, config);
}

const createLocationGroup = (token, newLocationGroupObject, imageFile) => {
    const config = setAuthHeader(token);
    return instance.post('/locations', createFormDataHelper(newLocationGroupObject, imageFile), config);
}

const returnStagingLocation = (token, locationId) => {
    const config = setAuthHeader(token);
    return instance.put('/locations/staging/return/' + locationId, null, config);
}

const rejectStagingLocation = (token, locationId) => {
    const config = setAuthHeader(token);
    return instance.delete('/locations/staging/reject/' + locationId, config);
}

const deleteStagingLocation = (token, locationId) => {
    const config = setAuthHeader(token);
    return instance.delete('/locations/staging/delete/' + locationId, config);
}

const locationsAPI = {
    // public locations + mp 
    getAllLocations,
    // get single location
    getLocationById,
    // user's staging measure points
    getStagingLocationsData,
    // staging measure points
    getAllStagingLocationsData,
    //number of staging locations
    getStagingLocationCount,
    // locations + measure points
    getAllLocationData,
    // all geoJSON in organization 
    getAllGeoFeatures,
    // geoJSONs for logged in user
    getAllGeoFeaturesData,
    // get location's status code
    getStatusCode,
    // update location info
    updateLocation,
    // update group
    updateLocationGroup,
    // update user's staging location
    updateUserStagingLocation,
    // submit saved user's staging location
    updateSubmitStagingLocation,
    // update and review staging location
    updateStagingLocation,
    // review staging location
    updateReviewStagingLocation,
    // approve reviewed staging location
    updateApproveStagingLocation,
    // create new staging location
    createStagingLocation,
    // create and review staging location
    createAndReviewStagingLocation,
    // create new location
    createLocation,
    //delete single location
    deleteLocation,
    // create new group
    createLocationGroup,
    // return staging location to reviewer
    returnStagingLocation,
    // reject staging location
    rejectStagingLocation,
    //delete own staging location
    deleteStagingLocation
}

export default locationsAPI;
