import React, { useState, useRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AlertSnackbar, PictureGallery } from 'components';
import { Badge, Button, FormLabel, Alert, Typography, Link } from '@mui/material';
import { FileUploadOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';

export default function PictureUploadButton(props) {
    const { setFiles, setImages, single, defaultImage, onDelete, imageDescs, setImageDescs, acceptVideo, setLocationFormPictures } = props;
    const defaultPicture = defaultImage ? [defaultImage] : [];
    const [pictures, setPictures] = useState(defaultPicture);
    const [deleteFlag, setDeleteFlag] = useState(true);
    const [error, setError] = useState(null);
    const [alert, setAlert] = useState({ open: false });
    const locale = useIntl().locale;
    const fileInputRef = useRef(null);
    const localeLinkExtension = locale === 'hr' ? `/deed.${locale}` : '/';

    useEffect(() => {
        if (defaultImage && pictures[0] && pictures[0].url === defaultImage.url) setDeleteFlag(false);
        else if (defaultImage && pictures[0] && pictures[0].url !== defaultImage.url) setDeleteFlag(true);
        else if (!defaultImage && !pictures.length) setDeleteFlag(false);
        else if (!defaultImage && pictures.length) setDeleteFlag(true);
        else setDeleteFlag(false);
        if (typeof (setLocationFormPictures) === 'function') setLocationFormPictures(pictures);

    }, [defaultImage, pictures, setDeleteFlag, setLocationFormPictures]);

    const handleInputChange = event => {
        let files = event.target.files;
        if (!files.length) return;

        const descs = [];
        const imgs = [];
        for (const picture of files) {
            if (!(picture.type.includes('image') || picture.type.includes('video'))) setError(<FormattedMessage id="PHOTO_UPLOAD_ERROR" />);
            else imgs.push({ name: picture.name, url: URL.createObjectURL(picture) });

            if (imageDescs) descs.push({ name: picture.name });
        }

        setPictures(imgs);
        if (setFiles) setFiles(files);
        if (imageDescs) setImageDescs(descs);
        if (setImages) setImages(imgs);
    }

    const handleInputClick = event => {
        event.target.value = null;
        setError(null);
    }

    const handleDelete = () => {
        if (setFiles) setFiles([]);
        if (setImages) setImages([]);
        if (setImageDescs) setImageDescs([]);
        setPictures(defaultPicture);
    }

    const updateImageDesc = (newDesc, imageName, index) => {
        const updatedImage = { name: imageName, desc: newDesc };
        const newArray = [...imageDescs];
        newArray[index] = updatedImage;
        setImageDescs(newArray);
    }


    const handleDeleteCallback = (index) => {
        setPictures(pictures.filter((p, i) => i !== index));
        if (imageDescs) setImageDescs(imageDescs.filter((p, i) => i !== index));
        if (onDelete) {
            onDelete(index);
        }
    }

    return <Box>
        <AlertSnackbar onClose={() => setAlert({ ...alert, open: false })} open={alert.open} severity={alert.severity} message={alert.message} />
        <Box sx={{ pb: pictures.length ? 2 : 0 }}>
            <FormLabel>
                <Badge
                    color="primary"
                    badgeContent={pictures.length}
                    variant={single ? "dot" : "standard"}
                    invisible={!deleteFlag}
                >
                    <FormattedMessage id={`PICTURE${!single ? "S" : ""}`} />
                </Badge>
            </FormLabel>
            <Button
                sx={{ mx: 1, alignItems: 'center' }}
                startIcon={<FileUploadOutlined />}
                variant="text"
                onClick={() => fileInputRef.current.click()}
            >
                <FormattedMessage id={!!pictures.length ? `FILE${!single ? "S" : ""}_CHANGE` : `FILE${!single ? "S" : ""}_SELECT`} />
            </Button>
            {deleteFlag && <Button color="warning" onClick={handleDelete} startIcon={<DeleteOutlineOutlined />}><FormattedMessage id={`FILE${pictures.length > 1 ? "S" : ""}_DELETE`} /></Button>}
        </Box>
        {error !== null && error}
        <input
            name="pictures"
            type="file"
            multiple={!single}
            accept={"image/png, image/jpeg, image/jpg" + (acceptVideo ? ", video/*" : null)}
            ref={fileInputRef}
            onChange={handleInputChange}
            onClick={handleInputClick}
            style={{ display: "none" }}
        />
        <PictureGallery pictures={pictures} onDelete={(index) => handleDeleteCallback(index)} imageDesc={imageDescs} onDescChange={updateImageDesc} />
        <Alert icon={false} severity="warning" variant="outlined" sx={{ mt: 1 }}>
            <Typography variant="caption">
                <FormattedMessage id="ATTRIBUTION.P1" /><Link href={"https://creativecommons.org/publicdomain/zero/1.0" + localeLinkExtension} underline="hover" target="_blank"><FormattedMessage id="ATTRIBUTION.P2" /></Link>
            </Typography>
        </Alert>
    </Box>;
}