import { useState } from 'react';
import { useSelector } from "react-redux";
import { useIntl, FormattedMessage } from 'react-intl';
import { selectMeasurePoints } from 'redux/locationsSlice';
import { selectAccessibilityOptionsConf, selectTourismTypesConf, selectAttractionCategoryTypesConf } from 'redux/configurationSlice';
import { Badge, Card, CardActions, Autocomplete, TextField, ButtonGroup, Collapse, IconButton, Button, Typography, Tooltip, Stack, ToggleButtonGroup, ToggleButton, useTheme, styled } from "@mui/material";
import { MoreVertOutlined, TuneOutlined, SearchOutlined, Castle, Forest, QuestionMark, FilterAltOffOutlined } from "@mui/icons-material";


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 3,
        top: 9,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

export default function LocationSearchFilters(props) {
    const { searchValue, attractionValues, tourismValues, tourismMethod, accessibilityValues, accessibilityMethod, filterHandler } = props;
    const intl = useIntl();
    const theme = useTheme();
    const locations = useSelector(selectMeasurePoints);
    const attractionTypesConf = useSelector(selectAttractionCategoryTypesConf);
    const accessibilityTypesConf = useSelector(selectAccessibilityOptionsConf);
    const tourismTypesConf = useSelector(selectTourismTypesConf);
    const [expand, setExpand] = useState(false);

    const renderIcon = (type, color) => {
        switch (type) {
            case 2:
                return <Castle sx={color ? { color: color } : null} />;
            case 1:
                return <Forest sx={color ? { color: color } : null} />;
            case 0:
            default:
                return <QuestionMark sx={color ? { color: color } : null} />;
        }
    };

    return <Card>
        <CardActions>
            <ButtonGroup sx={{ width: '100%' }} variant="text" size="small">
                <SearchOutlined color="primary" sx={{ my: 'auto', mr: 1 }} />
                <Autocomplete
                    id="location-name-search"
                    options={locations.map(el => el.name)}
                    value={searchValue}
                    onKeyUp={(e) => filterHandler("search", e.target.value)}
                    freeSolo
                    fullWidth
                    size="small"
                    renderInput={(props) => <TextField {...props} placeholder={intl.formatMessage({ id: "SEARCH" }) + '...'} />}
                />
                <ToggleButtonGroup
                    size="small"
                    sx={{ mx: 0.5 }}
                    value={attractionValues}
                    onChange={(e, data) => filterHandler("attraction", data)}
                    aria-label="text formatting"
                >
                    {attractionTypesConf.map(el => {
                        const selected = attractionValues.includes(el.key);
                        return (
                            <ToggleButton sx={selected ? { bgcolor: theme.palette.primary.main } : null} key={el.key} value={el.key} aria-label={el.value}>
                                <Tooltip title={<FormattedMessage id={"ATTRACTION." + el.value} />} arrow>
                                    {renderIcon(el.key, selected && theme.palette.primary.contrastText)}
                                </Tooltip>
                            </ToggleButton>
                        )
                    })}
                </ToggleButtonGroup>
                <StyledBadge badgeContent={expand ? 0 : tourismValues.length + accessibilityValues.length} color="primary">
                    <Tooltip title={<FormattedMessage id="ADDITIONAL_FILTERS" />} placement="left">
                        <IconButton onClick={() => setExpand(!expand)}><MoreVertOutlined /></IconButton>
                    </Tooltip>
                </StyledBadge>
            </ButtonGroup>
        </CardActions>
        <Collapse in={expand} timeout="auto" unmountOnExit>
            <CardActions sx={{ px: 2 }}>
                <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <TuneOutlined color="primary" />
                        <Typography variant="h6" sx={{ p: 1 }}><FormattedMessage id="ADDITIONAL_FILTERS" /></Typography>
                        <Badge badgeContent={tourismValues.length + accessibilityValues.length} color="primary" sx={{ pl: 1 }} />
                        {!!(tourismValues.length || accessibilityValues.length) && <Tooltip title={<FormattedMessage id="CLEAR_ALL" />} placement="right" arrow><IconButton onClick={() => filterHandler()} sx={{ ml: 2 }}><FilterAltOffOutlined /></IconButton></Tooltip>}
                    </span>

                    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
                        <Autocomplete
                            id="location-tourism-search"
                            multiple
                            options={tourismTypesConf}
                            getOptionLabel={option => intl.formatMessage({ id: 'TOURISM.' + option.value })}
                            value={tourismValues}
                            onChange={(e, data) => filterHandler("tourism", data)}
                            freeSolo
                            fullWidth
                            filterSelectedOptions
                            disableCloseOnSelect
                            size="small"
                            renderInput={(props) => <TextField {...props} placeholder={intl.formatMessage({ id: "TOURISM.TYPE" })} />}
                        />

                        <ButtonGroup size="small">
                            <Tooltip title={<FormattedMessage id="INCLUDE_SOME" />} arrow>
                                <Button variant={tourismMethod === "some" ? "contained" : "outlined"} onClick={(e) => filterHandler("tourismMethod", "some")}>
                                    <FormattedMessage id="SOME" />
                                </Button>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="INCLUDE_EVERY" />} arrow>
                                <Button variant={tourismMethod === "every" ? "contained" : "outlined"} onClick={(e) => filterHandler("tourismMethod", "every")}>
                                    <FormattedMessage id="EVERY" />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Stack>


                    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
                        <Autocomplete
                            id="location-accessibility-search2"
                            multiple
                            options={accessibilityTypesConf}
                            getOptionLabel={option => intl.formatMessage({ id: 'ACCESSIBILITY_OPTIONS.' + option.value })}
                            value={accessibilityValues}
                            onChange={(e, data) => filterHandler("accessibility", data)}
                            freeSolo
                            fullWidth
                            filterSelectedOptions
                            disableCloseOnSelect
                            size="small"
                            renderInput={(props) => <TextField {...props} placeholder={intl.formatMessage({ id: "ACCESSIBILITY_OPTIONS" })} />}
                        />

                        <ButtonGroup size="small">
                            <Tooltip title={<FormattedMessage id="INCLUDE_SOME" />} arrow>
                                <Button variant={accessibilityMethod === "some" ? "contained" : "outlined"} onClick={(e) => filterHandler("accessibilityMethod", "some")}>
                                    <FormattedMessage id="SOME" />
                                </Button>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="INCLUDE_SOME" />} arrow>
                                <Button variant={accessibilityMethod === "every" ? "contained" : "outlined"} onClick={(e) => filterHandler("accessibilityMethod", "every")}>
                                    <FormattedMessage id="EVERY" />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Stack>
                </Stack>
            </CardActions>
        </Collapse>
    </Card>
}