import { useEffect, useState } from 'react';
import { Typography, Alert, Stack, Card, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { SideDrawer, PictureGallery, AlertSnackbar, ConfirmationDialog } from 'components';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { formatDateLocale } from 'utils';

import API from 'api';

const default_state = {
    title: <FormattedMessage id='ALBUM_DETAILS' />,
    display: null,
};

export const AlbumDetails = (props) => {
    const { locationName, notes, pictures, addedBy, timestamp, albumId, locationFormFlag } = props;
    const CustomText = (props) => {
        return <Stack direction='column'>
            <Typography variant='body2' color='primary.main'><FormattedMessage id={props.messageId} /></Typography>
            {props.component ? props.component : <Typography variant='body1'>{props.text}</Typography>}
        </Stack>
    };

    return (
        <Stack direction="column" spacing={2}>
            {timestamp && <CustomText messageId="TIMESTAMP" text={formatDateLocale(timestamp)} />}
            {locationName && <CustomText messageId="LOCATION_NAME" text={locationName} />}
            {addedBy && <CustomText messageId="STAGING.USER" text={addedBy} />}
            <CustomText messageId="ALBUM_NOTES" component={<Card variant='outlined' sx={{ p: 0.5, fontStyle: 'italic' }}>
                {!notes ? <Typography variant='body1' color="text.disabled"><FormattedMessage id='NO_DATA' /></Typography>
                    : <Typography variant='body1'>{notes}</Typography>
                }
            </Card>}
            />
            <Box sx={theme => ({ width: theme.breakpoints.down("sm") ? '100%' : 400 })}>
                <PictureGallery showName pictures={pictures} previewMode={true} albumId={albumId} setFileDelete={props?.setFileDelete} locationFormFlag={locationFormFlag}/>
            </Box>
        </Stack>
    )
}

export default function SingleAlbumPreview(props) {
    const { onClose, albumId, locationName } = props;
    const { token } = useSelector(userSelector);
    const [albumData, setAlbumData] = useState(default_state);
    const [alert, setAlert] = useState({ open: false });
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [showFileDelete, setShowFileDelete] = useState({ show: false, imageId: null, callEffect: false });
    const deleteAlbumPermission = useSelector((state) => permissionSelector(state, 'delete-album'));
    const deleteAlbumFilePermission = useSelector((state) => permissionSelector(state, 'delete-album-file'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (albumId) {
            API.albums.getSingleAlbum(token, albumId).then(data => {
                if (data.data) setAlbumData({
                    title: <FormattedMessage id='ALBUM_DETAILS' />,
                    display: <AlbumDetails setFileDelete={setShowFileDelete} albumId={albumId} locationName={locationName} notes={data.data.note} addedBy={data.data.addedBy.username} timestamp={data.data.timestamp} pictures={data.data.albumFiles.map(el => ({ name: el.description, type: el.type, url: process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + el.path, id: el._id }))} />
                });
            }).catch(e => {
                console.error(e);
                setAlbumData({
                    title: <FormattedMessage id="ERROR.LOAD_FAILED" />,
                    display: <Alert sx={{ bgcolor: 'rgb(0,0,0,0)' }} severity="error"><FormattedMessage id="ERROR.ERROR" /></Alert>,
                    error: true
                })
            });
        }
        return () => {
            setAlbumData(default_state);
            setShowFileDelete({ show: false, imageId: null, callEffect: false });
        }
    }, [albumId, token, showFileDelete.callEffect, locationName]);

    const handleAlbumDelete = () => {
        if (albumId && deleteAlbumPermission) {
            API.albums.deleteSingleAlbum(token, albumId).then(response => {
                if (response.status === 200) {
                    setAlert({ open: true, message: { id: "ALBUM_DELETE_SUCCESS" }, severity: "success" });
                    setOpenConfirmDialog(false);
                    onClose();
                    props?.deleteFlag();
                }
            }).catch(error => {
                setAlert({ open: true, message: error.data, severity: "error" });
            });
        }
    }

    const handleDeleteAlbumFile = (fileId) => {
        if (albumId && fileId && deleteAlbumFilePermission) {
            API.albums.deleteSingleAlbumItem(token, albumId, fileId).then(response => {
                if (response) {
                    setAlert({ open: true, message: { id: "FILE_DELETE_SUCCESS" }, severity: "success" });
                    setShowFileDelete({ show: false, imageId: null, callEffect: true });
                }
            }).catch(error => {
                setAlert({ open: true, message: error.data, severity: "error" });
            });
        }
    }

    const toggleDrawer = () => {
        onClose();
        setAlbumData(default_state);
    }

    return (
        <div>
            <ConfirmationDialog
                open={openConfirmDialog}
                title={<FormattedMessage id="CONFIRM_ALBUM_DELETE" />}
                handleCancel={() => setOpenConfirmDialog(false)}
                handleCustomButton={handleAlbumDelete}
                customButtonTitle={<FormattedMessage id="DELETE" />}
            />
            <ConfirmationDialog
                open={showFileDelete.show}
                title={<FormattedMessage id="ALBUM_FILE_ALERT" />}
                handleCancel={() => setShowFileDelete({ show: false, imageId: null, callEffect: false })}
                handleCustomButton={() => handleDeleteAlbumFile(showFileDelete.imageId)}
                customButtonTitle={<FormattedMessage id="DELETE" />}
                customButtonColor='error'
            />
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message} />
            <SideDrawer deleteButton={deleteAlbumPermission && albumData.display !== null && !albumData.error} handleDeleteItem={() => setOpenConfirmDialog(true)} open={Boolean(albumId)} toggleDrawer={toggleDrawer} state={albumData} />
        </div>
    )
}