import { EmptyState } from "layouts";
import AuthKeycloak from 'api/AuthKeycloak';
import { FormattedMessage } from "react-intl";
import { Button } from "@mui/material";
import { LoginOutlined } from "@mui/icons-material";


export default function LoginRequired(props) {
    const { messageId } = props;
    return <EmptyState message={<Button onClick={() => AuthKeycloak('login-required')} endIcon={<LoginOutlined fontSize="small" />}><FormattedMessage id={messageId ? messageId : "LOGIN_REQUIRED"} /></Button>}/>
}