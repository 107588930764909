import React from 'react';
import {
    Card,
    CardContent,
    Autocomplete,
    TextField,
    Typography,
    Grid,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';
import { DateRangeSelector } from 'components';
import { selectMeasurePoints } from 'redux/locationsSlice';
import { selectReportIncidentTypesConf } from 'redux/configurationSlice';
import { useSelector } from 'react-redux';

const IncidentReportsFilter = ({ selectedLocations, selectedReportTypes, filterHandler, pictureSample }) => {

    const locations = useSelector(selectMeasurePoints);
    const reportTypesConfig = useSelector(selectReportIncidentTypesConf);
    const intl = useIntl();

    return (
        <Card sx={{ width: '100%' }}>
            <CardContent>
                <Grid container className="filter-tile" justifyContent="space-between" spacing={2}>

                    <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                        <Typography sx={{ pb: 1 }} variant="h6"><FormattedMessage id="DATE_SELECTED" /></Typography>
                        <DateRangeSelector column />
                    </Grid>

                    <Grid item xl={3} lg={3} md={3} sm={4} xs={12} className='filter-item attraction-location-select' sx={{ alignItems: 'space-between' }}>
                        <Typography sx={{ pb: 2 }} variant="h6"><FormattedMessage id="ATTRACTION.LOCATION" />:</Typography>
                        <Autocomplete
                            multiple
                            size="small"
                            limitTags={3}
                            disableCloseOnSelect
                            id="multiple-limit-tags"
                            options={locations}
                            getOptionLabel={(location) => location.name}
                            value={selectedLocations}
                            onChange={(e, location) => filterHandler(location.filter(mapedLocation => locations.map(filteredLocation => filteredLocation === mapedLocation)), 'locations')}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField {...params} key={locations.map(location => location._id)} label={<FormattedMessage id="ALL_LOCATIONS" />} />
                            )}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                        <Typography sx={{ pb: 2 }} variant="h6"><FormattedMessage id="FILTERS" />:</Typography>
                        <Autocomplete
                            multiple
                            size="small"
                            limitTags={3}
                            disableCloseOnSelect
                            id="multiple-limit-tags"
                            options={reportTypesConfig}
                            getOptionLabel={(reportType) => intl.formatMessage({ id: `INCIDENT_REPORT_TYPE_${reportType.value}` })}
                            value={selectedReportTypes}
                            onChange={(e, reportType) => filterHandler(reportType.filter(mapedReportType => reportType.map(filteredType => filteredType === mapedReportType)), 'reportType')}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField {...params} key={locations.map(location => location._id)} label={<FormattedMessage id="INCIDENT_REPORT_TYPE" />} />
                            )}
                            fullWidth
                        />
                        <FormControlLabel
                            control={<Checkbox checked={pictureSample} onChange={() => filterHandler(!pictureSample, 'picture')} />}
                            label={<Typography variant="subtitle1" ><FormattedMessage id="CONTAINS_PICTURE" /></Typography>}
                            sx={{ pt: 2 }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
export default IncidentReportsFilter;