import React from "react";
import { Typography, Button, useMediaQuery, TextField, DialogActions, Divider, Stack, DialogContent } from "@mui/material";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useState, useEffect } from "react";
import { PictureUploadButton, AlertSnackbar, LoadingData } from 'components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { imageResize, resizeVideo, isImage, isVideo, stopResize } from "utils"
import API from 'api';

const widthToResize = 1000
const heightToResize = 1000
const videoNewWidth = 640;
const videoNewHeight = 480;

function AddImagesForm({ location, open, onClose, onSubmit, refreshData, locationFormNewAlbum, setLocationFormNewAlbum, setUploadedFlag, setLocationFormResizedItems, setLocationFormImageDescriptions, setLocationFormPictures }) {
    const fullScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const [files, setFiles] = useState([]);
    const { token } = useSelector(userSelector);
    const [description, setDescription] = useState("");
    const [submittedFlag, setSubmittedFlag] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [canceled, setCanceled] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [imageDescriptions, setImageDescriptions] = useState([]);
    const [descLimitOverFlow, setDescLimitOverFlow] = useState(false);
    const [noteLimitOverFlow, setNoteLimitOverFlow] = useState(false);

    const app = useSelector((state) => state.app);


    const resetForm = () => {
        setFiles([]);
        setDescription("");
        setSubmittedFlag(false);
        setShowLoading(false);
    }

    const closeDialogHandler = () => {
        onClose();
        resetForm();
        stopResize();
    }

    const onSubmitHandler = () => {
        let resizedItems = [];
        var fileCount = 0;

        const resetSubmit = () => {
            setShowLoading(false);
            setSubmittedFlag(false);
        };

        async function resizeAndSendAllFiles() {
            for (const item of files) {
                fileCount += 1;
                let resizedFile = null;
                if (isImage(item.name)) resizedFile = await imageResize(item, widthToResize, heightToResize);
                if (isVideo(item.name)) resizedFile = await resizeVideo(item, videoNewWidth, videoNewHeight);

                if (resizedFile !== null) resizedItems.push(resizedFile);
            }

            if (fileCount === files.length && resizedItems.length && !canceled) {
                const newAlbum = {
                    locationId: location._id,
                    note: description,
                }
                setSubmittedFlag(true);
                if (!locationFormNewAlbum) {
                    API.albums.createAlbum(token, newAlbum, resizedItems, imageDescriptions).then((items) => {
                        setAlert({ open: true, message: { id: "ALBUM_ADDED" }, severity: "success" });
                        closeDialogHandler();
                        if (refreshData) refreshData();

                    }).catch(error => {
                        resetSubmit();
                        setAlert({ open: true, message: "error", severity: "error" });
                        console.error("Error posting new album:", error);
                    })
                }
                else {
                    setLocationFormNewAlbum(newAlbum);
                    setLocationFormResizedItems(resizedItems);
                    setLocationFormImageDescriptions(imageDescriptions)
                    setUploadedFlag(true);
                    closeDialogHandler();
                }
            }
            else if (canceled) {
                setCanceled(false);
                setAlert({ open: true, message: { id: "CANCEL_RESIZE" }, severity: "info" });
            }
            else if (!resizedItems.length) {
                resetSubmit();
                setAlert({ open: true, message: { id: "ERROR.RESIZE" }, severity: "error" })
            }
        }


        setShowLoading(true);
        resizeAndSendAllFiles(); // call the function
    }

    useEffect(() => {
        if (description.length > 256) {
            setNoteLimitOverFlow(true);
            setAlert({ open: true, message: { id: "ALBUM_NOTE_OVERFLOW" }, severity: "error" });
        }
        else {
            setAlert({ open: false });
            setNoteLimitOverFlow(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [description])

    useEffect(() => {
        let flag = false;
        for (let i = 0; i < imageDescriptions.length; i++) {
            if (imageDescriptions[i].desc && imageDescriptions[i].desc.length > 160) {
                flag = true;
                break;
            }
        }
        setDescLimitOverFlow(flag);
        if (flag) setAlert({ open: true, message: { id: "ALBUM_DESCRIPTION_OVERFLOW" }, severity: "error" });
        else setAlert({ open: false });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageDescriptions])

    return (<>
        <AlertSnackbar onClose={() => setAlert({ ...alert, open: false })} open={alert.open} severity={alert.severity} message={alert.message} />
        <Dialog
            open={open}
            fullScreen={fullScreen}
            fullWidth maxWidth='sm'
            scroll='paper'
        >
            <DialogTitle>
                <FormattedMessage id="ADD_ALBUM" />
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <TextField
                        id="outlined-read-only-input"
                        label={<FormattedMessage id="LOCATION" />}
                        value={location ? location.name : ""}
                        InputProps={{
                            readOnly: true,
                        }}
                        sx={{ backgroundColor: app.darkTheme ? '#6d6d6d' : '#f5f5f5', width: '100%' }}
                    />
                    <Typography color="info" variant="caption"><FormattedMessage id="ALBUM_NOTE" /></Typography>
                    <PictureUploadButton /*acceptVideo*/ setFiles={setFiles} imageDescs={imageDescriptions} setImageDescs={setImageDescriptions} onDelete={(index) => setFiles(files => [...files].filter((p, i) => i !== index))} setLocationFormPictures={setLocationFormPictures} />
                    <TextField
                        sx={{ backgroundColor: "none", width: "100%" }}
                        id="textarea"
                        multiline
                        rows={3}
                        name="Description"
                        label={<FormattedMessage id="DESCRIPTION" />}
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        margin="normal"
                        variant="outlined"
                    />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button disabled={submittedFlag} color="warning" variant="text" onClick={() => { setCanceled(true); closeDialogHandler(); }}>
                    <FormattedMessage id="CANCEL" />
                </Button>
                {!showLoading ? <Button disabled={submittedFlag || !(files.length) || descLimitOverFlow || noteLimitOverFlow} onClick={onSubmitHandler}>
                    <FormattedMessage id="SUBMIT" />
                </Button> : <LoadingData noText size={2}><FormattedMessage id="WAIT_MESSAGE" /></LoadingData>
                }
            </DialogActions>
        </Dialog>
    </>
    )
}

export default AddImagesForm;
