import React, { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { permissionSelector, userSelector } from 'redux/userSlice';
import { useSelector } from 'react-redux';
import API from 'api';

import { ListItemButton, ListItemIcon, ListItemText, Divider, List, Tooltip, Badge } from '@mui/material';
import { HomeOutlined, LocationOnOutlined, EditLocationOutlined, ReportOutlined, QuestionMarkOutlined } from '@mui/icons-material';

const BadgeNumber = forwardRef((props, ref) => {
    const { token } = useSelector(userSelector);
    const viewerPermission = useSelector((state) => permissionSelector(state, 'view-staging-location'));
    const reviewerPermission = useSelector((state) => permissionSelector(state, 'review-staging-location'));
    const approverPermission = useSelector((state) => permissionSelector(state, 'approve-staging-location'));
    const [badgeNumber, setBadgeNumber] = useState(0);
    const {touchRippleRef, ...rest} = props;

    useEffect(() => {
        const getBadgeNumber = async (role) => {
            let promise = await API.locations.getStagingLocationCount(token, role);
            setBadgeNumber(promise ? promise.data : 0);
        }
        
        if (approverPermission) getBadgeNumber("approve");
        else if (reviewerPermission) getBadgeNumber("review");
        else if (viewerPermission) getBadgeNumber("view");
        else setBadgeNumber(0);
    }, [token, viewerPermission, reviewerPermission, approverPermission]);
    
    return <Badge {...rest} ref={ref} color='primary' badgeContent={badgeNumber}><EditLocationOutlined /></Badge>;
});

const homeArray = [
    {
        item: 'HomePage',
        onClick: (navigateCallback) => navigateCallback("/map"),
        iconElement: <HomeOutlined />,
        textElement: <FormattedMessage id="HOME" />
    },
];

const aboutArray = [
    {
        item: 'AboutPage',
        onClick: (navigateCallback) => navigateCallback("/about"),
        iconElement: <QuestionMarkOutlined />,
        textElement: <FormattedMessage id="INNOVET_PROJECT" />
    },
];

const locationsArray = [
    {
        item: 'LocationsPage',
        onClick: (navigateCallback) => navigateCallback("/locations"),
        iconElement: <LocationOnOutlined />,
        textElement: <FormattedMessage id="LOCATIONS" />
    },
    {
        item: 'StagingLocationsPage',
        onClick: (navigateCallback) => navigateCallback("/staging-locations"),
        iconElement: <BadgeNumber/>,
        textElement: <FormattedMessage id="STAGING_LOCATIONS" />,
        permission: "view-staging-location"
    },
];

const samplesArray = [
    // {
    //     item: 'SamplesPage',
    //     onClick: (navigateCallback) => navigateCallback("/addImagesForm"),
    //     iconElement: <CollectionsOutlined />,
    //     textElement: <FormattedMessage id="MENU.SAMPLES" />
    // },
    // {
    //     item: 'StagingSamplesPage',
    //     onClick: (navigateCallback) => navigateCallback("/staging-samples"),
    //     iconElement: <ImageSearchOutlined />,
    //     textElement: <FormattedMessage id="STAGING.SAMPLES" />,
    //     permission: "view-staging-sample"
    // },
    {
        item: 'IncidentReportsPage',
        onClick: (navigateCallback) => navigateCallback("/incident-reports"),
        iconElement: <ReportOutlined />,
        textElement: <FormattedMessage id="INCIDENT_REPORTS" />,
        permission: "view-report"
    },
];

const CustomListButton = (props) => {
    const { item, selectedItem, open } = props;
    
    const navigate = useNavigate();
    const permissionObject = {
        'view-staging-location': useSelector((state) => permissionSelector(state, 'view-staging-location')),
        'view-staging-sample': useSelector((state) => permissionSelector(state, 'view-staging-sample')),
        'view-report': useSelector((state) => permissionSelector(state, 'view-report')),
    };
    const permissionArray = [];
    
    Object.keys(permissionObject).forEach(permission => permissionObject[permission] && permissionArray.push(permission));

    if (item.permission && !permissionArray.includes(item.permission)) return null;
    return <ListItemButton key={item.item} selected={item.item === selectedItem} onClick={() => item.onClick(navigate)}>
        <ListItemIcon>
            <Tooltip title={open ? "" : item.textElement} placement="right" arrow>
                {item.iconElement}
            </Tooltip>
        </ListItemIcon>
        <ListItemText primary={item.textElement} />
    </ListItemButton>
}


export default function CustomMenuItems(props) {
    const { secondaryItems } = props;

    return <List component="nav" style={{ height: 1 }}>
        {homeArray.map((item, i) => <CustomListButton key={i} item={item} {...props} />)}
        <Divider sx={{ my: 1 }} />
        {locationsArray.map((item, i) => <CustomListButton key={i} item={item} {...props} />)}
        <Divider sx={{ my: 1 }} />
        {aboutArray.map((item, i) => <CustomListButton key={i} item={item} {...props} />)}
        <Divider sx={{ my: 1 }} />
        {secondaryItems ? samplesArray.map((item, i) => <CustomListButton key={i} item={item} {...props} />) : null}
    </List>;
};