import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectStatusCodesConf } from "redux/configurationSlice";
import { Chip } from "@mui/material";
import { SentimentVeryDissatisfiedOutlined, SentimentSatisfiedOutlined, SentimentDissatisfied, SentimentNeutral } from "@mui/icons-material";
import API from 'api';
import { FormattedMessage } from "react-intl";

export default function ComplianceTag(props) {
    const { locationId, status } = props;
    const statusCodes = useSelector(selectStatusCodesConf).COMPLIANCE;
    const [complianceStatus, setComplianceStatus] = useState(null);
    useEffect(() => {
        const getColorIcon = (index) => {
            switch (index) {
                case 1:
                    return { icon: <SentimentSatisfiedOutlined />, color: "primary" };
                case 2:
                    return { icon: <SentimentDissatisfied />, color: "warning" };
                case 3:
                    return { icon: <SentimentVeryDissatisfiedOutlined />, color: "error" };
                case 0:
                default:
                    return { icon: <SentimentNeutral />, color: "default" };
            }
        }
        if (locationId) {
            API.locations.getStatusCode(locationId).then(({ data }) => {
                if (data && data.statusCode) {
                    const statusCode = data.statusCode.COMPLIANCE ? data.statusCode.COMPLIANCE : data.statusCode.compliance;
                    const code = statusCodes.find(el => el.key === statusCode);
                    setComplianceStatus({
                        ...getColorIcon(statusCode),
                        label: <FormattedMessage id={`SAMPLE_LOG.STATUS.COMPLIANCE.${code ? code.value : "UNKNOWN"}`} />
                    });
                }
                else setComplianceStatus({
                    ...getColorIcon(0),
                    label: <FormattedMessage id="SAMPLE_LOG.STATUS.COMPLIANCE.UNKNOWN" />
                });
            });
        }
        else if (status >= 0) {
            const code = statusCodes.find(el => el.key === status);
            setComplianceStatus({
                ...getColorIcon(status),
                label: <FormattedMessage id={`SAMPLE_LOG.STATUS.COMPLIANCE.${code ? code.value : "UNKNOWN"}`} />
            });
        }
    }, [locationId, status, statusCodes]);



    return complianceStatus && <Chip size="small" sx={props.sx} color={complianceStatus.color} icon={complianceStatus.icon} label={complianceStatus.label} />;
}