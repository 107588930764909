import { useEffect, useState, useCallback } from 'react';
import { Grid, CardMedia, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import SingleAlbumPreview from './SingleAlbumPreview';
import { LoadingData, LoginRequired } from 'components';
import API from 'api';


export default function LocationAlbumGallery(props) {
    const { location, refreshData, setRefreshData } = props;
    const { token } = useSelector(userSelector);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingNew, setLoadingNew] = useState(false);
    const [preview, setPreview] = useState(false);
    const [deleteAlbumFlag, setDeleteAlbumFlag] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(0);
    useEffect(() => {

        const setFalse = () => {
            setRefreshData(false);
            setLoading(false);
        }
        if (refreshData) {
            setPage(0);
        }
        if (token && page === 0) API.albums.getAlbums(token, location._id, page, 8).then(data => {
            if (data.data && data.data.docs) {
                setData(data.data.docs);
                setLimit(data.data.totalDocs);
            }
            else setData([]);

            setFalse()
        }).catch(e => {
            console.error(e);
            setFalse()
        });
        else setFalse()

        return () => {
            setDeleteAlbumFlag(false);
            setFalse()
        }
    }, [loading, location, token, deleteAlbumFlag, page, refreshData, setRefreshData]);

    const handleLoadMore = useCallback(() => {
        setLoadingNew(true);
        API.albums.getAlbums(token, location._id, page + 1, 8).then(data => {
            if (data.data && data.data.docs) {
                setData(oldData => [...oldData, ...data.data.docs]);
                setPage(page => page + 1);
                setLimit(data.data.totalDocs);
            }
            setLoadingNew(false);
        }).catch(e => {
            console.error(e);
            setLoadingNew(false);
        });
    }, [token, location, page]);


    const MiddleText = (props) => <div style={{ marginLeft: 'auto', marginRight: 'auto', paddingTop: 12 }}>{props.children}</div>

    return loading ? <MiddleText><LoadingData size={2} /></MiddleText> : <>
        <Grid item container direction="row" spacing={2}>
            <SingleAlbumPreview albumId={preview} locationName={location.name} onClose={() => setPreview(false)} deleteFlag={() => { setDeleteAlbumFlag(true); setRefreshData(true); }} />
            {data.map(el => <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={el._id} sx={{ width: '100%' }}>
                <CardMedia
                    component="img"
                    height="250"
                    sx={{ cursor: 'pointer', borderRadius: 1, boxShadow: 2 }}
                    onClick={() => setPreview(el._id)}
                    image={process.env.REACT_APP_API_BASE_URL + "/" + el.thumb}
                    alt={"card-media-" + el._id}
                />
            </Grid>)}
        </Grid>
        {!token && data.length === 0 ? <MiddleText>
            <LoginRequired messageId="LOGIN_MORE.GALLERY" />
        </MiddleText> : null}
        {token && data.length !== limit ? <MiddleText>
            <Button color="primary" onClick={handleLoadMore}>{loadingNew ? <FormattedMessage id="LOADING_MORE" /> : <FormattedMessage id="LOAD_MORE" />}</Button>
        </MiddleText> : null}
    </>
}