import { Box, Typography, useMediaQuery } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectResourcesTypesConf } from "redux/configurationSlice";
import { formatDateLocale } from 'utils';


export default function MeasurementKPIs(props) {
    const { values } = props;
    const resourceTypes = useSelector(selectResourcesTypesConf)
    const mediumScreen = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const xtraSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

    /**
     * screen size is XS - KPI width is 100%
     * screen size is SM - KPI width is 50%
     * screen size is MD - KPI width is 33%
     * screen size is LG - KPI width is 25%
     */
    const width = xtraSmallScreen ? '100%' : smallScreen ? '50%' : mediumScreen ? '33%' : '25%';


    return values.map((data, index) => {
        const resourceType = resourceTypes.find(el => el.key === data.resourceKey);
        if (resourceType) {
            const value = parseFloat(data.value).toFixed(data.value % 1 > 0 ? 2 : 0)
            return <Box key={index} sx={{ display: 'inline-block', width: width, paddingBottom: '32px', paddingRight: '16px' }}>
                <Typography variant="subtitle1" display="block"><FormattedMessage id={resourceType.name} /></Typography>
                <Typography color="primary.main" variant="h4" display="inline">{value}</Typography>
                <Typography variant="body1" display="inline">{resourceType.unit}</Typography>
                <Typography color="text.secondary" variant="caption" display="block">{formatDateLocale(data.timestamp)}</Typography>
            </Box>
        }
        else return null;

    })

}