import React, { useState, useEffect, useCallback } from 'react';
import { CustomDataGrid, IncidentReportsFilter, ReportDetails, SideDrawer, AlertSnackbar } from 'components';
import { Stack, Card, CardContent, useMediaQuery, Alert } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { selectDateRange } from "redux/dateRangeSlice";
import { formatDateLocale } from 'utils';
import { selectReportIncidentTypesConf } from "redux/configurationSlice";
import { selectIncidentsTablePageSize, selectIncidentsTablePage, setIncidentsTablePageSize, setIncidentsTablePage } from 'redux/appSlice';
import API from 'api';


const IncidentReportsView = ({ title }) => {

    const [filteredData, setFilteredData] = useState([]);
    const [details, setDetails] = useState({});

    const selectedDateRange = useSelector(selectDateRange);
    const [locations, setLocations] = useState([]);
    const [reportType, setReportType] = useState([]);
    const [pictureSample, setPictureSample] = useState(false);

    const [alert, setAlert] = useState({ open: false });
    const [openDrawer, setDrawer] = useState(false);

    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const statusReportTypes = useSelector(selectReportIncidentTypesConf);
    const intl = useIntl();

    const dispatch = useDispatch();
    const incidentsTablePageSize = useSelector(selectIncidentsTablePageSize);
    const incidentsTablePage = useSelector(selectIncidentsTablePage);
    const [pageSize, setPageSize] = useState(incidentsTablePageSize || 25);
    const [page, setPage] = useState(incidentsTablePage || 0);

    const { token } = useSelector(userSelector);

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const columns = [
        {
            field: 'name',
            headerName: intl.formatMessage({ id: 'LOCATION_NAME' }),
            ...(!smallScreen && { flex: 1 }),
            ...(smallScreen && { width: 150 }),
        },
        {
            field: 'incidentDescription',
            headerName: intl.formatMessage({ id: 'INCIDENT_DESCRIPTION' }),
            ...(!smallScreen && { flex: 1 }),
            ...(smallScreen && { width: 150 }),
        },
        {
            field: 'dateCreated',
            headerName: intl.formatMessage({ id: 'INCIDENT_DATE_CREATED' }),
            ...(!smallScreen && { flex: 1 }),
            ...(smallScreen && { width: 150 }),
        },
        {
            field: 'incidentReportType',
            headerName: intl.formatMessage({ id: 'INCIDENT_REPORT_TYPE' }),
            ...(!smallScreen && { flex: 1 }),
            ...(smallScreen && { width: 150 }),
        },
    ];

    const handleData = (data) => {
        setDetails({
            title: <FormattedMessage id='INCIDENT_REPORT' />,
            subtitle: <Alert variant="outlined" severity="warning" sx={{ p: 0, border: 'none' }}>
                <FormattedMessage id={`INCIDENT_REPORT_TYPE_${statusReportTypes.find(element => element.key === data.row.incidentType).value}`} />
            </Alert>,
            display: <ReportDetails name={data.row.name} timestamp={formatDateLocale(data.row.dateCreated)} locationName={data.row.name} pictures={data.row.pictures} user={data.row.createdBy.username} notes={data.row.incidentDescription} />
        });
        setDrawer(true);
    }

    const handlePageSizeChange = (newSize) => {
        setPageSize(newSize);
        dispatch(setIncidentsTablePageSize(newSize))
    }
    const handlePageChange = (newPage) => {
        setPage(newPage);
        dispatch(setIncidentsTablePage(newPage))
    }

    useEffect(() => {
        let dataRows = [];

        API.incidentReports.getAllIncidentReports(token, selectedDateRange.dateFrom, selectedDateRange.dateTo, locations, reportType, pictureSample)
            .then((items) => {
                items.data.forEach(item => {
                    const sReportType = statusReportTypes.find(element => element.key === item.reportType).value;
                    const incidentType = `INCIDENT_REPORT_TYPE_${sReportType}`;

                    const oneRow = {
                        id: item._id,
                        name: item.locationId.name,
                        incidentDescription: item.description,
                        dateCreated: formatDateLocale(item.createdAt),
                        incidentReportType: intl.formatMessage({ id: incidentType }),
                        incidentType: item.reportType,
                        pictures: item.pictures,
                        ...item,
                    }
                    dataRows.push(oneRow)
                });
                setFilteredData(dataRows);
            })
            .catch((error) => {
                console.error("Error fetching data, reason: ", error);
                setAlert({ open: true, message: error.data, severity: "error" });
            });
    }, [intl, token, selectedDateRange, locations, reportType, pictureSample, statusReportTypes]);

    const filterHandler = useCallback((data, type) => {
        switch (type) {
            case 'locations':
                setLocations(data);
                return;
            case 'reportType':
                setReportType(data);
                return;
            case 'picture':
                setPictureSample(data);
                return;
            default:
                setLocations([]);
                setReportType([]);
                setPictureSample(false)
                return;
        }
    }, []);

    return (
        <Stack direction="column" spacing={1} sx={{ width: '100%', height: '100%' }}>
            <IncidentReportsFilter
                filterHandler={filterHandler}
                selectedLocations={locations}
                selectedReportTypes={reportType}
                pictureSample={pictureSample}
            />

            <Card sx={{ width: '100%' }}>
                <CardContent>
                    <CustomDataGrid
                        rows={filteredData}
                        columns={columns}
                        handleData={handleData}
                        page={page} rowsPerPage={pageSize} onPageSizeChange={handlePageSizeChange} onPageChange={handlePageChange}
                    />
                </CardContent>
                <AlertSnackbar noAnchor open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message} />
                <SideDrawer state={details} open={openDrawer} toggleDrawer={() => setDrawer(false)} />
            </Card>
        </Stack>
    );
}
export default IncidentReportsView;