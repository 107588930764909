import { useSelector } from "react-redux";
import { useParams } from 'react-router';
import { FormattedMessage } from "react-intl";
import { selectLocationDetails, selectLocations } from 'redux/locationsSlice';
import { userSelector } from "redux/userSlice";
import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { LocationDetailsView, LocationMeasurePointCardView } from 'components';



function LocationsPage(props) {
    const { group, details } = props;
    const groupLocation = useSelector(selectLocations)[0];
    const locationId = useParams().locationId;
    const location = useSelector((state) => selectLocationDetails(state, locationId));

    if (group) return <EmptyState component={<LocationMeasurePointCardView locationGroupId={groupLocation._id} />} />;
    else if (locationId && location && details) return <EmptyState component={<LocationDetailsView location={location} publicView />} />;
    else return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;
}

export default function LocationsPageComponent(props) {
    const { group, details } = props;
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<LocationsPage loggedIn group={group} details={details} />} />;
    else return <PublicLayout page={<LocationsPage group={group} details={details} />} />;
};