import instance, { setAuthHeader } from './axiosEnv';
import { localISOString } from 'utils';

const getAllAlarms = (token, dateFrom = null, dateTo = null, locationIds = '', severities = '', acknowledged = false, unacknowledged = false, cleared = false, active = false) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));

    for (let el in locationIds) {
        queryParams.append('locationId', locationIds[el]);
    }
    for (let el in severities) {
        queryParams.append('severity', severities[el]);
    }

    queryParams.append('acknowledged', acknowledged);
    queryParams.append('unacknowledged', unacknowledged);
    queryParams.append('cleared', cleared);
    queryParams.append('active', active);

    return instance.get('/alarms?' + queryParams.toString(), config);
}

const getSingleAlarm = (token, alarmId) => {
    const config = setAuthHeader(token);
    return instance.get('/alarms/' + alarmId, config);
}

const acknowledgeAlarm = (token, alarmId) => {
    const config = setAuthHeader(token);
    return instance.put('/alarms/acknowledged/' + alarmId, null, config);
}

const clearAlarm = (token, alarmId) => {
    const config = setAuthHeader(token);
    return instance.put('/alarms/clear/' + alarmId, null, config);
}

const alarmsAPI = {
    getAllAlarms,
    getSingleAlarm,
    acknowledgeAlarm,
    clearAlarm,
}
export default alarmsAPI;