import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { MapView } from 'components';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';



function MapPage(props) {
    const { publicView } = props;
    return <EmptyState component={<MapView publicView={publicView} />} />;
}

export default function MapPageComponent(props) {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<MapPage />} />;
    else return <PublicLayout page={<MapPage publicView />} />;
};
