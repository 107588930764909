import React, { useState, useEffect } from "react";
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "redux/appSlice";
import { languages } from "lang";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Tooltip, Stack, Avatar, Grid } from '@mui/material';


function LanguageAvatar(props) {
    const languageList = {};
    Object.keys(languages).forEach(lang => {
        if (lang === 'en') languageList['gb'] = languages[lang][`LANGUAGE.${lang}`];
        else languageList[lang] = languages[lang][`LANGUAGE.${lang}`];
    })
    return <Tooltip title={languageList[props.lang]} arrow>
        <Avatar onClick={props.onClick} sx={theme => ({ width: 30, height: 26, cursor: 'pointer', border: `2px solid ${props.active ? theme.palette.primary.main : 'transparent'}` })} alt={props.lang} src={`https://raw.githubusercontent.com/hampusborgos/country-flags/main/png250px/${props.lang}.png`} />
    </Tooltip>
}


export default function PrivacyPolicyDialog() {
    const [open, setOpen] = useState(JSON.parse(localStorage.getItem('privacyPolicy')) ? false : true);
    const [policy, setPolicy] = useState(JSON.parse(localStorage.getItem('privacyPolicy')) ? true : false);
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const handleLangChange = (lang) => {
        dispatch(setLanguage(lang))
    }

    const handleOk = () => {
        setOpen(false);
        setPolicy(true)
    }

    useEffect(() => {
        localStorage.setItem('privacyPolicy', JSON.stringify(policy));
    }, [policy]);


    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ zIndex: 99999 }}
        >

            <Grid container sx={{ alignItems: "center", justifyContent: 'space-between', pr: 2 }}>
                <Grid item >
                    <DialogTitle id="alert-dialog-title" sx={{ fontSize: 23 }}>
                        <FormattedMessage id="PRIVACY_POLICY.TITLE" />
                    </DialogTitle>
                </Grid>

                <Grid item sx={{ pl: 3 }}>
                    <Stack spacing={1} direction='row' justifyContent="center" alignItems="center" >
                        {Object.keys(languages).map((lang, index) => (
                            <LanguageAvatar key={index} active={app.lang === lang} lang={lang === "en" ? "gb" : lang} onClick={() => handleLangChange(lang)} />
                        ))}
                    </Stack>
                </Grid>
            </Grid>

            <DialogContent>
                <DialogContentText sx={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="PRIVACY_POLICY.SUBTITLE_OVERVIEW" />
                </DialogContentText>

                <DialogContentText >
                    <FormattedMessage id="PRIVACY_POLICY.OVERVIEW_CONTENT" />
                </DialogContentText>

                <DialogContentText sx={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="PRIVACY_POLICY.SUBTITLE_COLLECTING" />
                </DialogContentText>

                <DialogContentText>
                    <FormattedMessage id="PRIVACY_POLICY.COLLECTING_CONTENT" />
                </DialogContentText>

                <DialogContentText sx={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="PRIVACY_POLICY.SUBTITLE_CONTACT" />
                </DialogContentText>

                <DialogContentText>
                    <FormattedMessage id="PRIVACY_POLICY.CONTACT_CONTENT" />
                    <Link>ivica.projic@ericssonnikolatesla.com</Link>
                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} autoFocus>
                    <FormattedMessage id="BUTTON.OK" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
