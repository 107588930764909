import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from 'react-redux';
import { userSelector } from "redux/userSlice";
import { selectResourceTypeGroupsConf } from "redux/configurationSlice";
import { Box, Card, CardHeader, CardContent, Tabs, Tab, useMediaQuery, Button, MenuItem, Menu, Typography } from "@mui/material";
import { ListOutlined } from '@mui/icons-material';
import { MeasurementKPIs, LoadingData, AlertSnackbar, ComplianceTag } from 'components';
import API from 'api';



export default function LatestMeasurementsTabs(props) {
    const { location } = props;
    const { token } = useSelector(userSelector);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const [tabValue, setTabValue] = useState(0);
    const [groups, setGroups] = useState([]);
    const [values, setValues] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const resTypeGroups = useSelector(selectResourceTypeGroupsConf);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        const values = [];
        resTypeGroups.forEach(group => {
            if (location.resourceTypes.filter(type => group.resourceTypes.includes(type)).length) values.push("TYPE_" + group.name);
        });
        if (location.serviceResourceTypes.length) values.push("TYPE_SERVICE");
        setGroups(values);
    }, [location, resTypeGroups, setGroups]);

    const renderTabs = useCallback(() => {
        return <Tabs
            variant="scrollable"
            scrollButtons
            value={tabValue}
            onChange={(e, index) => setTabValue(index)}
        >
            {groups.map(el => <Tab wrapped key={el} name={el} label={<FormattedMessage id={"SAMPLE_LOG." + el} />} />)}
        </Tabs>;

    }, [groups, tabValue, setTabValue]);

    const renderMenu = useCallback(() => {

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return <>
            <Button endIcon={<ListOutlined />} onClick={handleClick} variant="text" sx={{ textTransform: 'none' }}>
                {!!groups.length && <Typography>
                    <FormattedMessage id={"SAMPLE_LOG." + groups[tabValue]} />
                </Typography>}
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {groups.map((el, index) => (
                    <MenuItem key={el} name={el} onClick={() => setTabValue(index)}>
                        <Typography color={tabValue === index && "primary"}>
                            <FormattedMessage id={"SAMPLE_LOG." + el} />
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </>

    }, [groups, tabValue, setTabValue, anchorEl, setAnchorEl]);

    useEffect(() => {
        if (!groups.length) return;
        let resourceTypes;
        if (groups[tabValue] === "TYPE_ALL") resourceTypes = [];
        else if (groups[tabValue] === "TYPE_SERVICE") resourceTypes = location.serviceResourceTypes;
        else resourceTypes = location.resourceTypes.filter(type => {
            const filteredType = resTypeGroups.find(group => "TYPE_" + group.name === groups[tabValue]);
            if (filteredType) return filteredType.resourceTypes.includes(type);
            else return false;
        });

        setLoading(true);
        API.measurements.getLocationLatest(token, [location._id], resourceTypes).then((items) => {
            if (items && items.data && items.data.length) setValues(items.data.find(data => data.locationId === location._id).values);
            else setValues([]);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching data, reason: ", error);
            setAlert({ open: true, message: error.data, severity: "error" });
            setLoading(false);
        });
    }, [token, groups, tabValue, location, setValues, resTypeGroups]);

    const renderKPIs = useCallback(() => {
        if (loading) return <LoadingData noText size={3} />;
        else return <MeasurementKPIs values={values} />;
    }, [loading, values]);

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message} />
        <Card sx={{ width: '100%' }}>
            <CardHeader
                title={<div>
                    <FormattedMessage id="LOCATION.LATEST_MEASUREMENTS" />
                    <br/>
                    <ComplianceTag locationId={location._id} />
                </div>}
                action={smallScreen ?
                    renderMenu()
                    : <Box sx={{ maxWidth: '400px' }}>
                        {renderTabs()}
                    </Box>
                } />
            <CardContent>
                {renderKPIs()}
            </CardContent>
        </Card>
    </>
}