import { Alert, Snackbar, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";



export default function AlertSnackbar(props) {
    const { noAnchor, open, onClose, autoHide, severity, message } = props;
    const autoHideDuration = autoHide === "none" ? Number.MAX_VALUE : Number(autoHide);
    const messageId = (() => {
        if (message === "Unauthorized") return "ERROR.UNAUTHORIZED"
        else if (message && message.id) return message.id
        else return "ERROR"
    })();
    
    return <Snackbar
        anchorOrigin={!noAnchor ? { vertical: 'top', horizontal: 'center' } : undefined}
        open={open}
        onClose={onClose}
        key={"alert-message-" + messageId}
        autoHideDuration={autoHideDuration || 5000}
    >
        <Alert severity={severity} sx={{ width: '100%' }} onClose={onClose}>
            <Typography>
                <FormattedMessage id={messageId} />
            </Typography>
        </Alert>
    </Snackbar>
}