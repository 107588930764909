import instance, { setAuthHeader } from './axiosEnv';
import { localISOString } from 'utils';

const getAllIncidentReports = (token, dateFrom, dateTo, locations, reportType, reportPicture) => {

    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    queryParams.append('reportPicture', reportPicture);

    if (locations) {
        locations.forEach((location) => {
            queryParams.append('locationId', location._id);
        });
    }

    if (reportType) {
        reportType.forEach((report) => {
            queryParams.append('reportType', report.key);
        });
    }
    
    return instance.get('/reports?' + queryParams.toString(), config);
}

const incidentReportsAPI = {
    getAllIncidentReports
}

export default incidentReportsAPI;