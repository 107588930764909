import { createTheme } from "@mui/material";

const defaultTheme = createTheme();

export const light = (fontSize, fontWeight) => createTheme(
  {
    palette: {
      mode: "light",
      background: {
        paper: "#fff",
        default: "#fff",
      },
      text: {
        primary: "#11111",
      },
      scale: {
        primary: "#d8d8d8"
      },
      culturalHeritage: {
        main: '#795548',
        contrastText: '#ffffff',
      }
    },
    typography: {
      ...(fontSize && { fontSize }),
      ...(fontWeight && {
        h1: { fontWeight: defaultTheme.typography.h1.fontWeight + fontWeight },
        h2: { fontWeight: defaultTheme.typography.h2.fontWeight + fontWeight },
        h3: { fontWeight: defaultTheme.typography.h3.fontWeight + fontWeight },
        h4: { fontWeight: defaultTheme.typography.h4.fontWeight + fontWeight },
        h5: { fontWeight: defaultTheme.typography.h5.fontWeight + fontWeight },
        h6: { fontWeight: defaultTheme.typography.h6.fontWeight + fontWeight },
        subtitle1: { fontWeight: defaultTheme.typography.subtitle1.fontWeight + fontWeight },
        subtitle2: { fontWeight: defaultTheme.typography.subtitle2.fontWeight + fontWeight },
        body1: { fontWeight: defaultTheme.typography.body1.fontWeight + fontWeight },
        body2: { fontWeight: defaultTheme.typography.body2.fontWeight + fontWeight },
        button: { fontWeight: defaultTheme.typography.button.fontWeight + fontWeight },
        caption: { fontWeight: defaultTheme.typography.caption.fontWeight + fontWeight },
        overline: { fontWeight: defaultTheme.typography.overline.fontWeight + fontWeight },
      }),
    }
  },

);

const dark = (fontSize, fontWeight) => createTheme(
  {
    palette: {
      mode: "dark",
      background: {
        paper: "#222",
        default: "#222",
      },
      text: {
        primary: "#fff",
      },
      button: {
        color: "#8A2BE2"
      },
      scale: {
        primary: "#5b5b5b"
      },
      culturalHeritage: {
        main: '#8d6e63',
        contrastText: '#ffffff',
      }
    },
    typography: {
      ...(fontSize && { fontSize }),
      ...(fontWeight && {
        h1: { fontWeight: defaultTheme.typography.h1.fontWeight + fontWeight },
        h2: { fontWeight: defaultTheme.typography.h2.fontWeight + fontWeight },
        h3: { fontWeight: defaultTheme.typography.h3.fontWeight + fontWeight },
        h4: { fontWeight: defaultTheme.typography.h4.fontWeight + fontWeight },
        h5: { fontWeight: defaultTheme.typography.h5.fontWeight + fontWeight },
        h6: { fontWeight: defaultTheme.typography.h6.fontWeight + fontWeight },
        subtitle1: { fontWeight: defaultTheme.typography.subtitle1.fontWeight + fontWeight },
        subtitle2: { fontWeight: defaultTheme.typography.subtitle2.fontWeight + fontWeight },
        body1: { fontWeight: defaultTheme.typography.body1.fontWeight + fontWeight },
        body2: { fontWeight: defaultTheme.typography.body2.fontWeight + fontWeight },
        button: { fontWeight: defaultTheme.typography.button.fontWeight + fontWeight },
        caption: { fontWeight: defaultTheme.typography.caption.fontWeight + fontWeight },
        overline: { fontWeight: defaultTheme.typography.overline.fontWeight + fontWeight },
      }),
    }
  },

);

const themes = {
  light,
  dark
}

export default themes;