import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { image, normalFont, boldFont } from './documentAssets';
import { format, parse } from 'date-fns';
import { enGB, hr, sl, srLatn } from "date-fns/locale";
import { enUS, hrHR, srRS } from '@mui/material/locale';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

export function localISOString(date) {
    const dateISO = (new Date(date - (new Date(date)).getTimezoneOffset() * 60000)).toISOString().replace("Z", "");
    return dateISO;
}

export function timeObjectAggregation(aggregation) {
    let time = {};
    switch (aggregation) {
        case 'm':
            time = {
                unit: 'month',
                displayFormats: { month: 'MMM yyyy' }, // Aug 2022
                tooltipFormat: "MMM, yyyy",
            }
            break;
        case 'w':
            time = {
                unit: 'week',
                displayFormats: { week: 'yyyy ww' }, // 2022 34
                tooltipFormat: "yyyy, ww",
            }
            break;
        case 'd':
            time = {
                unit: 'day',
                displayFormats: { day: 'MMM d' }, // Aug 24
                tooltipFormat: "P",
            }
            break;
        case 'h':
            time = {
                unit: 'hour',
                displayFormats: { hour: 'MMM d, HH:mm' }, // Aug 24, 12:30
                tooltipFormat: "Pp",
            }
            break;
        default:
            time = {
                unit: 'day',
                displayFormats: { day: 'MMM d' }, // possible options: yyyy QQQ MMM ww d HH mm                
                tooltipFormat: "Pp",
            }
            break;
    }
    return time;
}

export const groupByKey = (items, key) =>
    items.reduce(
        (hash, { [key]: value, ...rest }) => (
            { ...hash, [value]: (hash[value] || []).concat({ [key]: value, ...rest }) }
        ), {});

export const sortbyName = (a, b) => {
    let name1 = Object.keys(a)[0].toUpperCase();
    let name2 = Object.keys(b)[0].toUpperCase();
    if (name1 < name2) return -1;
    if (name1 > name2) return 1;
    return 0;
}

export function formatDateLocale(date, options) {
    const { getFileTime, getShortFormat, aggregationMode } = options || {};
    const currentLocale = selectDateLocale(JSON.parse(localStorage.getItem('lang')));

    if (aggregationMode) {
        const { tooltipFormat } = timeObjectAggregation(aggregationMode);

        if (aggregationMode === 'w') {
            let dateTime;
            // if date is real date format ('2022-12-12T10:05:53.800Z', 1670839544689) - parsing is not needed, 
            // we parse because DB prepared week aggregation that is not recognised format in date-fns library
            if (date.includes('T') || typeof date === 'number') dateTime = date;
            else {
                dateTime = parse(
                    date.split('-')[1],
                    'ww',
                    new Date().setFullYear(date.split('-')[0]),
                    { weekStartsOn: 1, locale: currentLocale }
                )
            }

            return format(
                new Date(
                    dateTime
                ),
                tooltipFormat,
                { locale: currentLocale });
        }
        else return format(new Date(date), tooltipFormat, { locale: currentLocale });
    }

    // 2022-01 without aggregation this will be formatted as month, but DB is returning this response only for week aggregation
    if (typeof (date) === 'string' && date.lastIndexOf('-') === 4 && !aggregationMode) return date;

    if (isNaN(new Date(date).valueOf())) {
        return date;
    }

    if (getShortFormat) {
        return format(new Date(date), 'P', { locale: currentLocale });
    }

    if (getFileTime) return format(new Date(date), 'dd-MM-yyyy--HH-mm');

    // if no other options are selected return local datetime
    return format(new Date(date), 'Pp', { locale: currentLocale });
}

export function formatDate(date, options) {
    const { getDetailedTime, getGraphTime, getFileTime } = options || {};
    let hours, minutes, graphDay, graphDate;

    if (typeof (date) === 'string' && date.lastIndexOf('-') === 4) return date;

    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (isNaN(d.valueOf())) {
        return date;
    }
    if (getGraphTime) {
        graphDay = d.toString().split(' ')[0];
        graphDate = d.getDate();
        return [graphDay, graphDate].join(' ');
    }


    if (getDetailedTime || getFileTime) {
        hours = d.getHours();
        minutes = d.getMinutes();
    }
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (hours < 10)
        hours = '0' + hours;
    if (minutes < 10)
        minutes = '0' + minutes;
    const time = [year, month, day].join('-');

    if (hours !== undefined && minutes !== undefined) {
        if (getFileTime) return [time, [hours, minutes].join('-')].join('--');
        else return [time, [hours, minutes].join(':')].join('  ');
    } else return time;
}

export function statusCodeColor(statusCode) {
    let statusColor;
    switch (statusCode) {
        case 1: // --status-blue
            statusColor = '#0077DB'
            break;
        case 2: // --status-green
            statusColor = '#288964'
            break;
        case 3: // --status-yellow
            statusColor = '#DCAF00'
            break;
        case 4: // --status-orange
            statusColor = '#E66E19'
            break;
        case 5: // --status-red
            statusColor = '#DC2D37'
            break;
        default: // --status-black
            statusColor = '#181818'
            break;
    }

    return statusColor;
}


export function deepMerge(objA, objB) {
    const newObj = mergeObjects({}, objA);
    return mergeObjects(newObj, objB);
}

function mergeObjects(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeObjects(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeObjects(target, ...sources);
}

export function deepEqual(object1, object2, keysArray = []) {
    if (!object1 || !object2) return object1 === object2;

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (keysArray.length && !keysArray.includes(key.toString())) continue;
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

export function exportData(getDataType, locationName, type, tableData, tableHeaders, base64Image, printPDF) {
    const { getJsonData, getCsvData, getPdfData } = getDataType || {};
    locationName = locationName.replaceAll(" ", "-");
    if (getCsvData) {
        const data = tableData;
        let CSV = '';

        if (tableHeaders) {
            let row = tableHeaders;
            CSV = row + "\r\n";
        }

        for (let i = 0; i < data.length; i++) {
            let row = '';
            for (let index in data[i]) {
                row += '"' + data[i][index] + '",';
            }
            row.slice(0, row.length - 1);
            CSV += row + "\r\n";
        }

        if (CSV === '') {
            console.error('Invalid data for exporting CSV!');
            return;
        }

        let fileName = `${type}_${locationName}_${formatDateLocale(Date.now(), { getFileTime: true })}.csv`;
        let uri = 'data:text/csv;charset=utf-8,\uFEFF' + encodeURI(CSV);
        let link = document.createElement('a');
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    else if (getJsonData) {
        const myData = tableData;
        const fileName = `${type}_${locationName}_${formatDateLocale(Date.now(), { getFileTime: true })}`;
        const json = JSON.stringify(myData, null, 4);
        const blob = new Blob([json], { type: 'application/json' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    else if (getPdfData) {
        const doc = new jsPDF('l', 'pt', 'a4');
        const width = doc.internal.pageSize.getWidth();
        doc.addFileToVFS('EricssonHilda-Light-normal.ttf', normalFont);
        doc.addFont('EricssonHilda-Light-normal.ttf', 'Normal', 'normal');
        doc.addFileToVFS('EricssonHilda-Light-bold.ttf', boldFont);
        doc.addFont('EricssonHilda-Light-bold.ttf', 'Bold', 'bold');
        doc.setFont('Normal');
        if (base64Image !== undefined) {
            doc.setFontSize(12);
            doc.addImage(image, 'JPEG', 40, 20, 15, 15);
            doc.text(process.env.REACT_APP_TITLE, 40 + 18, 31);
            doc.text(formatDateLocale(new Date()), 40 + 650, 32);
            doc.setFontSize(14);
            doc.text(type, 380, 60)
            doc.addImage(base64Image, 'png', 50, 100, width - 100, 350);
            doc.addPage('a4', 'l');
        }
        doc.autoTable({
            head: [tableHeaders],
            body: tableData,
            theme: 'grid',
            styles: {
                font: 'Normal',
                fontStyle: 'normal',
                overflow: 'linebreak',
                fontSize: 10
            },
            headStyles: {
                fillColor: 'black'
            },
            didDrawPage: function (data) {
                doc.setFontSize(12);
                doc.addImage(image, 'JPEG', data.settings.margin.left, 20, 15, 15);
                doc.text(process.env.REACT_APP_TITLE, data.settings.margin.left + 18, 31);
                doc.text(formatDateLocale(new Date()), data.settings.margin.left + 650, 32);

                let numOfPages = doc.internal.getNumberOfPages();
                for (var i = 1; i <= numOfPages; i++) {
                    doc.setPage(i);
                    doc.text(780, 570, String(i));
                }
            }
        })
        if (printPDF) {
            doc.autoPrint();
            const blob = doc.output("bloburl");
            window.open(blob);
        }
        else {
            doc.save(`${type}_${locationName}_${formatDateLocale(Date.now(), { getFileTime: true })}.pdf`);
        }
    }
}

export const imageResize = (inputFile, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;

            img.onload = () => {
                const canvas = document.createElement("canvas");
                let width = img.width;
                let height = img.height;

                // Calculate new dimensions
                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                // Draw image on canvas
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);

                // Convert canvas to Blob
                canvas.toBlob((blob) => {
                    // Create new File object with the Blob and original file name
                    const file = new File([blob], inputFile.name, { type: inputFile.type });
                    resolve(file);
                }, inputFile.type);
            };

            img.onerror = reject;
        };

        reader.onerror = reject;
        reader.readAsDataURL(inputFile);
    });
};

const ffmpeg = createFFmpeg({ log: true });

export const stopResize = () => {
    if (ffmpeg.isLoaded()) ffmpeg.exit();
}

export const resizeVideo = async (inputFile, newWidth, newHeight) => {
    try {
        if (!ffmpeg.isLoaded()) await ffmpeg.load();

        ffmpeg.FS("writeFile", "input.mp4", await fetchFile(inputFile));
        await ffmpeg.run("-i", "input.mp4", "-vf", `scale=${newWidth}:${newHeight}`, '-preset', 'ultrafast', '-crf', '23', "output.mp4");
        const videoData = ffmpeg.FS("readFile", "output.mp4");

        const resizedBlob = new Blob([videoData], { type: inputFile.type });
        const videoFile = new File([resizedBlob], inputFile.name, { type: resizedBlob.type });
        return videoFile;

    } catch (error) {
        console.error("ERROR:", error)
        return null;
    }
};

export const isVideo = (url) => {
    return /\.(mp4|web|ogg|mov|avi|wmv)$/.test(url.toLowerCase())
}

export const isImage = (url) => {
    return /\.(jpg|jpeg|png|gif)$/.test(url.toLowerCase())
}

export const selectLocale = (lang, stringFormat) => {
    switch (lang) {
        case "hr":
            return stringFormat ? "hr" : hrHR;
        case "en":
            return stringFormat ? "en" : enUS;
        case "si":
            return stringFormat ? "sl" : hrHR;
        case "rs":
            return stringFormat ? "sr-Latn" : srRS;
        default:
            break;

    }
}

export const selectDateLocale = (lang) => {
    switch (lang) {
        case "hr":
            return hr;
        case "en":
            return enGB;
        case "si":
            return sl;
        case "rs":
            return srLatn;
        default:
            break;

    }
}
