import instance, { setAuthHeader } from './axiosEnv';

const getAlbums = (token, locationId, page, size) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('locationId', locationId);
    queryParams.append('page', page);
    queryParams.append('size', size);

    return instance.get('/albums?' + queryParams, config);
}

const getSingleAlbum = (token, albumId) => {
    const config = setAuthHeader(token);

    return instance.get('/albums/details/' + albumId, config);
}

const deleteSingleAlbum = (token, albumId) => {
    const config = setAuthHeader(token);
    return instance.delete('/albums/' + albumId, config);
}

const deleteSingleAlbumItem = (token, albumId, albumFileId) => {
    const config = setAuthHeader(token);
    return instance.put(`/albums/${albumId}/file/${albumFileId}`, null, config)
}

const createAlbumHelper = (newReportObject, files, fileDescs) => {
    const formData = new FormData();
    for (const key in newReportObject) {
        if (typeof newReportObject[key] !== 'string') {
            formData.append(key, JSON.stringify(newReportObject[key]));
        }
        else formData.append(key, (newReportObject[key]));
    }
    if (files){
        for (const file of files) {
           if(file) formData.append('files[]', file, file.name);
        }
    }

    if (fileDescs){
        let formatData = [];
        for (const file of fileDescs) {
            formatData.push({path: file.name, description: file.desc});
        }
        formData.append('files', JSON.stringify(formatData));
    }
    
    return formData;
}

const createAlbum = (token, newAlbumObject, files, fileDescs) => {
    const config = setAuthHeader(token);
    return instance.post('/albums', createAlbumHelper(newAlbumObject, files, fileDescs), config);
}

const albumsAPI = {
    getAlbums,
    getSingleAlbum,
    deleteSingleAlbum,
    deleteSingleAlbumItem,
    createAlbum,
}

export default albumsAPI;