import React, { useEffect } from 'react';
import GeoJSON from 'ol/format/GeoJSON.js';
import API from 'api';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';


function MapGeoJSON(props) {
    const { geoJSONLayer, disabled } = props;
    const user = useSelector(userSelector);

    useEffect(() => {
        geoJSONLayer.getSource().clear();        
        if (!disabled) {
            const geoFeatureApi = user.token ? API.locations.getAllGeoFeaturesData : API.locations.getAllGeoFeatures;
            geoFeatureApi(user.token)
                .then(({ data }) => {
                    geoJSONLayer.getSource().addFeatures(data.map(el => new GeoJSON().readFeatures(el.geoFeature, { featureProjection: "EPSG:3857" })).flat());
                })
                .catch(error => {
                    console.error("Error fetching data for GeoJSON, reason: ", error);
                });
        }
    }, [disabled, user.token, geoJSONLayer])

    return <></>;
}

export default React.memo(MapGeoJSON);