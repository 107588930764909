import { useSelector } from "react-redux";
import { useParams } from 'react-router';
import { FormattedMessage } from "react-intl";
import { selectLocations } from 'redux/locationsSlice';
import { permissionSelector } from 'redux/userSlice';
import { userSelector } from "redux/userSlice";
import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { LocationDetailsView, StagingLocationsTableView, UsersLocationsTableView } from 'components';



function LocationsStagingPage(props) {
    const { group, details } = props;
    const groupLocation = useSelector(selectLocations)[0];
    const locationId = useParams().locationId;
    const reviewerPermission = useSelector((state) => permissionSelector(state, 'review-staging-location'));
    const approverPermission = useSelector((state) => permissionSelector(state, 'approve-staging-location'));

    const basicUser = !(approverPermission || reviewerPermission);
    if (group && basicUser) return <EmptyState component={<UsersLocationsTableView />} />;
    else if (group && !basicUser) return <EmptyState component={<StagingLocationsTableView locationGroupId={groupLocation._id} />} />;
    else if (locationId && details) return <EmptyState component={<LocationDetailsView locationId={locationId} publicView />} />;
    else return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;
}

export default function LocationsStagingPageComponent(props) {
    const { group, details } = props;
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<LocationsStagingPage loggedIn group={group} details={details} />} />;
    else return <PublicLayout page={<EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />} />;
};