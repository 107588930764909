import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, Button, DialogActions, DialogContent } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const ConfirmationDialog = (props) => {
    const { open, title, content, handleCancel, handleCustomButton, customButtonTitle, customButtonColor } = props;
    const [clicked, setClicked] = useState(false);

    const handleClicked = () => {
        setClicked(true);
        handleCustomButton();
    }

    useEffect(() => {
        if (!open) setClicked(false);
    }, [open]);

    if (!open) return null;

    return (
        <Dialog onClose={handleCancel} open={open}>
            <DialogTitle> {title} </DialogTitle>
            <DialogContent> {content} </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={handleCancel}> <FormattedMessage id="CANCEL" /> </Button>
                <Button disabled={clicked} onClick={handleClicked} color={customButtonColor}> {customButtonTitle} </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ConfirmationDialog