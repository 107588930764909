import instance, { setAuthHeader } from './axiosEnv';
import { localISOString } from 'utils';

const getMeasurementsHistory = (token, locationId = '', resourceTypes = '', dateFrom = null, dateTo = null, sampleTypes = '', qualityTypes = '') => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));

    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }

    for (let el in resourceTypes) {
        queryParams.append('resourceType', resourceTypes[el]);
    }

    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el]);
    }

    return instance.get('/measurements?' + queryParams.toString(), config);
}

const getMultiTypeMeasurements = (token, locationId, resourceTypes = '', dateFrom = null, dateTo = null, sampleTypes = '', aggregation = null) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('locationId', locationId);
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));

    for (let el in resourceTypes) {
        queryParams.append('resourceType', resourceTypes[el]);
    }

    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el]);
    }

    queryParams.append('aggregation', aggregation);

    const root = token === null ? '/measurements/public/values?' : '/measurements/values?';

    return instance.get(root + queryParams.toString(), config);
}

const postMeasurements = (token, collector, locationId, values, files) => {
    const config = setAuthHeader(token);
    const data = [];
    const dataKeys = Object.keys(values);
    for (let index = 0; index < dataKeys.length; index++) {
        const key = dataKeys[index];
        if (key === 'notes' || key === 'timestamp'|| values[key] === "") continue;
        else data.push({ resourceTypeKey: key, value: values[key] });
    }

    const formData = new FormData();

    formData.append('samplingType', 3);
    formData.append('locationId', locationId);
    formData.append('collector', collector);
    formData.append('notes', values.notes);
    formData.append('timestamp', values.timestamp);
    formData.append('data', JSON.stringify(data));
    if (!!files && files.length) {
        for (const file of files) {
            formData.append('pictures[]', file, file.name);
        }
    }

    return instance.post('/samplingLogs/', formData, config);
}

const postHistoryMeasurements = (token, locationId, collector, file, skipFlag) => {
    const config = setAuthHeader(token);

    const queryParams = new URLSearchParams();

    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    queryParams.append('skipFlag', skipFlag);
    queryParams.append('collector', collector);

    const formData = new FormData();
    // formData.append('collector', collector);
    formData.append('file', file, file.name);

    return instance.post('/samplingLogs/history?' + queryParams.toString(), formData, config);
}

const getLocationLatest = (token, locationId = '', resourceTypes = '', date = new Date()) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    queryParams.append('date', localISOString(date));

    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }

    for (let el in resourceTypes) {
        queryParams.append('resourceType', resourceTypes[el]);
    }

    return instance.get('/measurements/latest?' + queryParams.toString(), config);
}


const measurementsAPI = {
    getMultiTypeMeasurements,
    postMeasurements,
    getLocationLatest,
    getMeasurementsHistory,
    postHistoryMeasurements,
}

export default measurementsAPI;
