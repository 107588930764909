import instance, { setAuthHeader } from './axiosEnv';
import { localISOString } from 'utils';

const getSamplingNumbers = (token, locationId = '', dateFrom = null, dateTo = null, qualityStatuses = '', sampleTypes = '', aggregation = null, collectors = '', samplePictures = false) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    queryParams.append('samplePictures', samplePictures);
    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    for (let el in qualityStatuses) {
        queryParams.append('qualityStatus', qualityStatuses[el])
    }
    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el])
    }
    for (let el in collectors) {
        queryParams.append('collector', collectors[el])
    }
    queryParams.append('aggregation', aggregation);

    return instance.get('/samplingLogs/samplingNumber?' + queryParams.toString(), config);
}

const getQualityLevelDistribution = (token, locationId = '', dateFrom = null, dateTo = null, qualityStatuses = '', sampleTypes = '', collectors = '', samplePictures = false) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    queryParams.append('samplePictures', samplePictures);
    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    for (let el in qualityStatuses) {
        queryParams.append('qualityStatus', qualityStatuses[el])
    }
    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el])
    }
    for (let el in collectors) {
        queryParams.append('collector', collectors[el])
    }

    return instance.get('/samplingLogs/qualityLevels?' + queryParams.toString(), config);
}

const getSamplingValues = (token, locationId = '', dateFrom = null, dateTo = null, qualityStatuses = '', sampleTypes = '', samplePictures = false, collectors = '') => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', new Date(dateFrom).toISOString());
    queryParams.append('dateTo', new Date(dateTo).toISOString());
    queryParams.append('samplePictures', samplePictures);
    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    for (let el in qualityStatuses) {
        queryParams.append('qualityStatus', qualityStatuses[el])
    }
    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el])
    }
    for (let el in collectors) {
        queryParams.append('collector', collectors[el])
    }

    return instance.get('/samplingLogs?' + queryParams.toString(), config);
}

const getSampleTypeDistribution = (token, locationId = '', dateFrom = null, dateTo = null, qualityStatuses = '', sampleTypes = '', collectors = '', samplePictures = false) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    queryParams.append('samplePictures', samplePictures);
    for (let el in locationId) {
        queryParams.append('locationId', locationId[el]);
    }
    for (let el in qualityStatuses) {
        queryParams.append('qualityStatus', qualityStatuses[el])
    }
    for (let el in sampleTypes) {
        queryParams.append('sampleType', sampleTypes[el])
    }
    for (let el in collectors) {
        queryParams.append('collector', collectors[el])
    }

    return instance.get('/samplingLogs/sampleTypes?' + queryParams.toString(), config);
}

const getSamplingDetails = (token, samplingLogId) => {
    const config = setAuthHeader(token);
    return instance.get('/samplingLogs/details/' + samplingLogId, config);
}

const samplingLogsApi = {
    getSamplingNumbers,
    getQualityLevelDistribution,
    getSampleTypeDistribution,
    getSamplingValues,
    getSamplingDetails
}
export default samplingLogsApi;