const fileList = require.context('/', true, /^\.\/(?!lang\/).*\.json$/);
const languages = {};

fileList.keys().forEach((fileName) => {
    const fileContent = fileList(fileName);
    const cleanFileName = fileName.replace('./', '').replace('.json', '');
    languages[cleanFileName] = fileContent;

});

export {
    languages
}