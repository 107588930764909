import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { updateLocationState, removeLocation } from "redux/locationsSlice";
import { selectAttractionCategoryTypesConf } from 'redux/configurationSlice';
import { LoadingData, AlertSnackbar, CustomDataGrid, NewStagingLocationDialog, ConfirmationDialog } from "components";
import { Tooltip, IconButton, Card, CardHeader, CardContent, Stack, Typography, Button, Switch, FormControlLabel, useMediaQuery } from '@mui/material';
import { ReviewsOutlined, AssignmentTurnedInOutlined, DeleteOutlined, AssignmentLateOutlined, CheckCircleOutlined, RateReviewOutlined, Undo } from '@mui/icons-material';
import API from 'api';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { selectLocationsTablePage, selectLocationsTablePageSize, setLocationsTablePage, setLocationsTablePageSize } from 'redux/appSlice';


const CustomButton = (props) => {
    const { breakpoint, onClick, disabled, sx, messageId, color, children } = props;

    if (!breakpoint) return <Button onClick={onClick} disabled={disabled} sx={{ ...sx, ':hover': { backgroundColor: 'transparent' } }} color={color || "primary"}>
        <FormattedMessage id={messageId} />
    </Button>
    else return <Tooltip title={<FormattedMessage id={messageId} />} arrow>
        <span>
            <IconButton onClick={onClick} disabled={disabled} sx={{ ...sx, ':hover': { backgroundColor: 'transparent' } }} color={color || "primary"}>
                {children}
            </IconButton>
        </span>
    </Tooltip>;
}


export default function StagingLocationsTableView(props) {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const mediumScreen = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const { token } = useSelector(userSelector)
    const updateRights = useSelector((state) => permissionSelector(state, 'update-staging-location'));
    const viewerPermission = useSelector((state) => permissionSelector(state, 'view-staging-location'));
    const reviewerPermission = useSelector((state) => permissionSelector(state, 'review-staging-location'));
    const approverPermission = useSelector((state) => permissionSelector(state, 'approve-staging-location'));
    const rejecterPermission = useSelector((state) => permissionSelector(state, 'reject-staging-location'));
    const attractionTypes = useSelector(selectAttractionCategoryTypesConf);
    const locationsTablePageSize = useSelector(selectLocationsTablePageSize);
    const locationsTablePage = useSelector(selectLocationsTablePage);
    const [data, setData] = useState([]);
    const [newLocationDialog, setNewLocationDialog] = useState(false);
    const [dialogData, setDialogData] = useState(null);
    const [stagingDataType, setStagingDataType] = useState(null);
    const [alert, setAlert] = useState({ open: false });
    const [loading, setLoading] = useState(true);
    const [submittedFlag, setSubmittedFlag] = useState(false);
    const [confirmDialogData, setConfirmDialogData] = useState({ open: false });
    const [pageSize, setPageSize] = useState(locationsTablePageSize || 25);
    const [page, setPage] = useState(locationsTablePage || 0);

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (reviewerPermission && approverPermission) setStagingDataType("approve");
        else if (reviewerPermission) return setStagingDataType("review");
        else if (approverPermission) return setStagingDataType("approve");
        else return setStagingDataType(null);
    }, [reviewerPermission, approverPermission]);

    useEffect(() => {
        const dataHandler = (data) => setData(data.map(el => ({
            ...el,
            id: el._id,
            reviewed: Boolean(el.reviewedBy),
            username: el.addedBy.username,
            type: "ATTRACTION." + attractionTypes.find(t => t.key === el.touristAttraction.attractionType).value
        })));
        const errorHandler = (e) => {
            console.error("Error fetching staging location: ", e);
            setAlert({ open: true, message: e.data, severity: "error" });
            setData({ error: e });
        }
        if (loading) {
            if (viewerPermission && stagingDataType === "review") API.locations.getAllStagingLocationsData(token, stagingDataType).then((data) => {
                if (data && data.data) dataHandler(data.data);
            }).catch(errorHandler);
            else if (viewerPermission && stagingDataType === "approve") API.locations.getAllStagingLocationsData(token, stagingDataType).then((data) => {
                if (data && data.data) dataHandler(data.data);
            }).catch(errorHandler);
            else return;
        }
        setLoading(false);
    }, [attractionTypes, loading, stagingDataType, viewerPermission, token])

    const renderTable = useCallback(() => {
        const dataHandlerHelper = (data, type) => {
            setAlert({ open: true, message: { id: "SUCCESS." + type }, severity: "success" });
            if (type === "APPROVE") dispatch(updateLocationState(data.data));
            setLoading(true);
            setSubmittedFlag(false);
        };
        const errorHandlerHelper = (error) => {
            console.error("An error has occured ", error);
            setAlert({ open: true, message: error.data, severity: "error" });
            setSubmittedFlag(false);
        }


        const handleView = (data) => {
            setDialogData(data);
            setNewLocationDialog(true);
        }
        const handleReview = (data) => {
            setSubmittedFlag(true);
            API.locations.updateReviewStagingLocation(token, data.id).then(data => dataHandlerHelper(data, "REVIEW")).catch(errorHandlerHelper)
            setConfirmDialogData({ open: false });
        }
        const handleApprove = (data) => {
            setSubmittedFlag(true);
            API.locations.updateApproveStagingLocation(token, data.id).then(data => dataHandlerHelper(data, "APPROVE")).catch(errorHandlerHelper)
            setConfirmDialogData({ open: false });
        }
        const handleReject = (data) => {
            setSubmittedFlag(true);
            API.locations.rejectStagingLocation(token, data.id).then(data => dataHandlerHelper(data, "REJECT")).catch(errorHandlerHelper)
            setConfirmDialogData({ open: false });
        }
        const handleReturn = (data) => {
            setSubmittedFlag(true);
            API.locations.returnStagingLocation(token, data.id).then(data => dataHandlerHelper(data, "RETURN")).catch(errorHandlerHelper)
            setConfirmDialogData({ open: false });
        }

        const headers = [
            {
                field: 'reviewed',
                headerName: intl.formatMessage({ id: 'REVIEWED' }),
                ...(!smallScreen && { flex: 0 }),
                renderCell: (el) => (
                    el.value
                        ? <div>
                            <Tooltip title={<span><FormattedMessage id="REVIEWED_BY" /> {el.row.reviewedBy && el.row.reviewedBy.username}</span>} arrow>
                                <span>
                                    <AssignmentTurnedInOutlined color="primary" />
                                </span>
                            </Tooltip>
                        </div>
                        : <AssignmentLateOutlined color="disabled" />
                ),
                minWidth: 50
            },
            {
                field: 'type',
                headerName: intl.formatMessage({ id: 'ATTRACTION.TYPE' }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: ({ value }) => <FormattedMessage id={value} />,
                minWidth: 100
            },
            {
                field: 'name',
                headerName: intl.formatMessage({ id: 'NAME' }),
                ...(!smallScreen && { flex: 2 }),
                minWidth: 150
            },
            {
                field: 'username',
                headerName: intl.formatMessage({ id: 'STAGING.USER' }),
                ...(!smallScreen && { flex: 2 }),
                minWidth: 150
            }
        ];

        if (stagingDataType === "approve") headers.push({
            field: 'reviewUser',
            headerName: intl.formatMessage({ id: 'STAGING.REVIEWER' }),
            ...(!smallScreen && { flex: 2 }),
            renderCell: (el) => (<Typography>{el.row.reviewedBy && el.row.reviewedBy.username}</Typography>),
            minWidth: 150
        });
        headers.push({
            field: 'actions',
            headerName: intl.formatMessage({ id: 'ACTIONS' }),
            type: 'actions',
            getActions: (el) => {
                const display = (cond) => {
                    if (!cond) return { display: 'none' };
                    else return null;
                }

                const actions = [
                    <GridActionsCellItem
                        key="view"
                        label={<CustomButton breakpoint={mediumScreen} onClick={() => handleView(el.row)} disabled={!updateRights || submittedFlag} messageId="UPDATE"><RateReviewOutlined fontSize="small" /></CustomButton>}
                        showInMenu
                        sx={display(stagingDataType === "review" || stagingDataType === "approve")}
                    />,
                    <GridActionsCellItem
                        key="return"
                        label={<CustomButton breakpoint={mediumScreen} onClick={() => setConfirmDialogData({ open: true, title: <FormattedMessage id="STAGING.CONFIRM.RETURN" />, buttonTitle: <FormattedMessage id="RETURN" />, customButton: () => handleReturn(el.row) })} messageId="RETURN"><Undo fontSize="small" /></CustomButton>}
                        showInMenu
                        sx={display(stagingDataType === "approve")}
                    />,
                    <GridActionsCellItem
                        key="reject"
                        label={<CustomButton breakpoint={mediumScreen} color="warning" onClick={() => setConfirmDialogData({ open: true, title: <FormattedMessage id="STAGING.CONFIRM.REJECT" />, buttonTitle: <FormattedMessage id="REJECT" />, customButton: () => handleReject(el.row) })} disabled={!rejecterPermission || submittedFlag} messageId="REJECT"><DeleteOutlined fontSize="small" /></CustomButton>}
                        showInMenu
                    />,
                ]

                if (stagingDataType === "approve") return [
                    <CustomButton breakpoint={mediumScreen} onClick={() => setConfirmDialogData({ open: true, title: <FormattedMessage id="STAGING.CONFIRM.APPROVE" />, buttonTitle: <FormattedMessage id="APPROVE" />, customButton: () => handleApprove(el.row) })} disabled={!el.row.reviewed || submittedFlag} messageId="APPROVE"><CheckCircleOutlined fontSize="small" /></CustomButton>, ...actions
                ];
                else if (stagingDataType === "review") return [
                    <CustomButton breakpoint={mediumScreen} onClick={() => setConfirmDialogData({ open: true, title: <FormattedMessage id="STAGING.CONFIRM.REVIEW" />, buttonTitle: <FormattedMessage id="REVIEW" />, customButton: () => handleReview(el.row) })} disabled={el.row.reviewed} messageId="REVIEW"><ReviewsOutlined fontSize="small" /></CustomButton>, ...actions
                ];

            },
            ...(!smallScreen && { flex: 2 }),
            minWidth: 150
        });


        const handleData = ({ row }) => {
            navigate('/staging-locations/details/' + row.id);
        }

        const getCellClassName = (params) => {
            if (params.field === '#' && params.value !== null) {
                return params.value;
            }
        }
        const handlePageSizeChange = (newSize) => {
            setPageSize(newSize);
            dispatch(setLocationsTablePageSize(newSize))
        }
        const handlePageChange = (newPage) => {
            setPage(newPage);
            dispatch(setLocationsTablePage(newPage))
        }
        if (loading) return <LoadingData noText size={2} />
        else if (data.error) return <Typography variant='body2'><FormattedMessage id="NO_DATA" /></Typography>;
        else return <CustomDataGrid customCellClass={getCellClassName} rows={data} columns={headers} handleData={handleData} page={page} rowsPerPage={pageSize} onPageSizeChange={handlePageSizeChange} onPageChange={handlePageChange} />;

    }, [loading, token, intl, data, updateRights, rejecterPermission, stagingDataType, mediumScreen, submittedFlag, smallScreen, navigate, dispatch, page, pageSize]);

    const closeDialogHandler = () => {
        setNewLocationDialog(false);
        setDialogData(null);
    };
    const handleSwitch = (e, data) => {
        if (data) setStagingDataType("review");
        else setStagingDataType("approve");
        setLoading(true);
    }

    const onDeleteHandler = (response) => {
        dispatch(removeLocation(response.data));
        setAlert({ open: true, message: { id: "SUCCESS.DELETE" }, severity: "success" });
        closeDialogHandler();
    }

    return <Stack direction="column" spacing={1} sx={{ width: '100%', height: '100%' }}>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} message={alert.message} />
        <ConfirmationDialog
            open={confirmDialogData.open}
            title={confirmDialogData.title}
            customButtonTitle={confirmDialogData.buttonTitle}
            handleCancel={() => setConfirmDialogData({ open: false })}
            handleCustomButton={confirmDialogData.customButton}
        />
        <Card>
            <CardHeader
                title={<FormattedMessage id="STAGING_LOCATIONS" />}
                subheader={stagingDataType && <FormattedMessage id={"ADMIN_SWITCH." + stagingDataType.toUpperCase()} />}
                action={
                    stagingDataType && <Tooltip disabled={!(reviewerPermission && approverPermission)} title={<FormattedMessage id="TOGGLE_MODE" />} arrow placement="left">
                        <span>
                            <FormControlLabel sx={!(reviewerPermission && approverPermission) ? { display: 'none' } : null} label={<Typography variant="button" color="info.main"><FormattedMessage id={"STAGING.PENDING." + stagingDataType.toUpperCase()} /></Typography>} control={<Switch size="small" onChange={handleSwitch} checked={stagingDataType === "review"} />} />
                        </span>
                    </Tooltip>
                }
            />
            <CardContent>
                {renderTable()}
            </CardContent>
        </Card>
        {dialogData && <NewStagingLocationDialog open={newLocationDialog} location={dialogData} onClose={closeDialogHandler} pinnedPosition={dialogData.position} onDelete={onDeleteHandler} onSubmit={() => {
            setAlert({ open: true, message: { id: "SUCCESS.UPDATE" }, severity: "success" });
            setLoading(true);
        }} />}
    </Stack>
}